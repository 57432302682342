import React, { Component } from "react";

/**
 * Basic component for select field
 */
export default class SelectField extends Component {
  constructor(props) {
    super(props);

    let multiple = props.attribs.multiple === "multiple" ? true : false;
    let value = multiple ? [] : props.attribs.value;

    props.children.forEach(c => {
      if (c.attribs.selected === "selected") {
        if (multiple) {
          value.push(c.attribs.value);
        } else {
          value = c.attribs.value;
        }
      }
    });

    this.state = {
      multiple: multiple,
      value: value,
    };
  }


  onChange(e) {
    if (this.state.multiple) {
      // TODO
    }

    this.setState({
      value: e.target.value
    });
  }

  render() {
    let options = this.props.children.map((c, index) => {
      return (
        <option key={index} value={c.attribs.value}>
          {c.children.length > 0 ? c.children[0].data : ""}
        </option>
      );
    });

    return (
      <select
        value={this.state.value}
        id={this.props.attribs.id}
        name={this.props.attribs.name}
        className={this.props.attribs.class}
        data-live-search={this.props.attribs["data-live-search"]}
        required={this.props.attribs.required}
        multiple={this.state.multiple ? "multiple" : ""}
        onChange={this.onChange.bind(this)}
      >
        {options}
      </select>
    );
  }
}