import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap";
import ParserHTML from "./../parser/ParserHTML";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from "bazinga-translator";

let parserHTML = new ParserHTML();

export default class ModalNote extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formNote: null,
            showModalNote: false,
            loadingFormNote: false,
            customerId: this.props.customerId
        };
    }

    initInput = () => {
        App.initSelectPicker();
        App.initDatePicker();
    }

    /**
     * Method to init form ModalNoteForm
     *
     * @param form
     */
    initNoteForm = (form) => {
        this.setState(
            {
                formNote: parserHTML.parse(form),
                showModalNote: true,
                loadingFormNote: false
            }, () => {
                this.initInput();
            }
        );
    }

    clickLoadModal = async () => {
        this.setState({
            loadingFormNote: true
        });

        let url = Routing.generate('arteo_bundle_note_add');

        if(this.state.customerId) {
            url += '?customerId=' + this.state.customerId;
        }

        try {
            let response = await fetch(url, {
                method: "GET",
                credentials: "same-origin"
            });

            if (response.status == 200) {
                let form = await response.text();

                this.initNoteForm(form);
            }
        } catch(error) {
            console.log(error)
        }
    }

    closeModalNote = () => {
        this.setState({
            showModalNote: false
        });
    }

    submitNoteForm = async () => {
        this.setState({
            loadingFormNote: true
        });

        let form = document.getElementById("note-form-modal");

        try {
            let url = Routing.generate('arteo_bundle_note_add');

            let response = await fetch(url, {
                method: "POST",
                body: new FormData(form),
                credentials: "same-origin"
            });

            if (response.status == 201) {

                let responseData = await response.json();

                this.closeModalNote();
            } else {
                let form = await response.text();

                this.initNoteForm(form);
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        let formModalNote = (
            <Modal
                size="lg"
                isOpen={this.state.showModalNote}
                toggle={this.closeModalNote.bind(this)}
                onOpened={this.initInput.bind(this)}
            >
                <ModalHeader toggle={this.closeModalNote.bind(this)}>
                    Ajouter une note
                </ModalHeader>
                <ModalBody>
                    {
                        this.state.loadingFormNote === true ?
                            (
                                <div className="text-center"><Spinner className="primary" /></div>
                            )
                            :
                            (
                              <div>
                                  {this.state.formNote}
                              </div>
                            )
                    }
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-primary"
                        onClick={this.submitNoteForm.bind(this)}
                    >
                        {Translator.trans("app.form.submit")}
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={this.closeModalNote.bind(this)}
                    >
                        {Translator.trans("app.form.cancel")}
                    </button>
                </ModalFooter>
            </Modal>
        );

        return (
            <div>
                <a className="nav-link" onClick={this.clickLoadModal.bind(this)}>
                    <i className="fas fa-phone"></i>
                </a>

                {formModalNote}
            </div>
        );
    }
}