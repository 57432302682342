import React from "react";

export default class FlashNotification extends React.Component {
    state = {
        notifications: []
    };

    componentWillReceiveProps(props) {
        let notification = props.notification;

        if (notification !== undefined) {
            let notifications = this.state.notifications;

            notifications.push(notification);

            this.setState({
                notifications: notifications
            });
        }
    }

    render() {
        return (
            <div>
                {this.state.notifications.map((notification, index) => {
                    return (
                        <div key={index} className={notification.type === "success" ? "alert alert-success" : "alert alert-danger"} role="alert">
                            {notification.message}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    );
                })}
            </div>
        );
    }
}
