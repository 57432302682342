import React from "react";
import { Spinner } from "reactstrap";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const numberFormatter = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" });
const colorText = "#e8ebe8";
const HtmlToReactParser = require("html-to-react").Parser;
const htmlToReactParser = new HtmlToReactParser();
class ProductViewer extends React.Component {
    state = {
        index: null,
        isLoading: false,
        productViewer: null,
    };

    getProductViewer = async () => {
        this.setState({ isLoading: true });

        const state = this.state;

        try {
            const response = await fetch(Routing.generate("arteo_admin_art_product_viewer_get", { id: this.props.productViewerId }), {
                method: "GET",
                credentials: "same-origin",
            });

            if (response.status == 200) {
                const productViewer = await response.json();

                state.productViewer = productViewer;

                if (productViewer.items.length > 0) {
                    state.index = 0;
                }
            }
        } catch (error) {
            console.log(error);
        }

        state.isLoading = false;

        this.setState(state);
    };

    naviguate = (val) => {
        const state = this.state;
        let index = state.index;

        if (index == null) {
            return;
        }

        const itemsLength = state.productViewer.items.length;

        index = index + val;

        if (index < 0) {
            index = itemsLength - 1;
        }

        if (index > itemsLength - 1) {
            index = 0;
        }

        this.setState({
            index: index,
        });
    };

    renderItem(state) {
        const index = state.index;

        if (index == null) {
            return "";
        }

        const item = state.productViewer.items[index];
        const product = item.product;
        const artist = product.artist;

        return (
            <>
                <a href={product.fullImage} data-lightbox="product">
                    <img alt={product.title} src={product.midImage} />
                </a>

                <div className="mt-4 card" style={{ borderColor: "grey", backgroundColor: "black", color: colorText }}>
                    <div className="card-body">
                        <h3>{product.title}</h3>
                        <h4 className="mb-4">{artist && artist.fullName}</h4>

                        <h5 className="mb-4">
                            {product.category} / {product.dimension.height} x {product.dimension.width}
                            {product.dimension.depth !== 0 && product.dimension.depth !== undefined && product.dimension.depth !== null
                                ? " x " + product.dimension.depth
                                : ""}{" "}
                            / {product.nature} / {numberFormatter.format(product.wantedPrice)}
                        </h5>

                        <div>{artist && htmlToReactParser.parse(artist.description)}</div>
                    </div>
                </div>
            </>
        );
    }

    componentDidMount() {
        this.getProductViewer();
    }

    render() {
        const state = this.state;

        return (
            <div className="text-center mt-2">
                <img alt="logo" className="mb-4" src="/assets/themes/back/img/logo.png" />

                <h1 className="mb-4" style={{ color: colorText, fontWeight: "700" }}>
                    Votre sélection d'oeuvres
                </h1>

                <div className="mb-4">
                    <button className="btn btn-default mr-2" onClick={this.naviguate.bind(this, -1)}>
                        Précédent
                    </button>
                    <button className="btn btn-default" onClick={this.naviguate.bind(this, 1)}>
                        Suivant
                    </button>
                </div>

                <div className="mx-4 mb-2">{state.isLoading ? <Spinner color="primary" /> : this.renderItem(state)}</div>
            </div>
        );
    }
}

export default ProductViewer;
