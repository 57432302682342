import React from "react";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap";
import ReactPaginate from "react-paginate";
import Translator from "bazinga-translator";
import ParserHTML from "../parser/ParserHTML";

let parserHTML = new ParserHTML();

class PhotothequeList extends React.Component {
    state = {
        artwork: null,
        artworks: [],
        isLoading: false,
        limit: 6,
        offset: 0,
        pagesTotal: 0,
        productForm: null,
    };

    getData = async () => {
        this.setState({ isLoading: true });

        const state = this.state;

        try {
            const response = await fetch(Routing.generate("arteo_admin_phototheque_get_data", { offset: state.offset, limit: state.limit }), {
                credentials: "same-origin",
                method: "GET",
            });

            if (response.status == 200) {
                const data = await response.json();

                state.artworks = data.artworks;
                state.pagesTotal = Math.ceil(data.total / state.limit);
            }
        } catch (error) {
            console.log(error);
        }

        state.isLoading = false;

        this.setState(state);
    };

    handleLimit = (event) => {
        this.setState({ limit: event.target.value }, () => this.getData());
    };

    handleOffset = (page) => {
        this.setState({ offset: Math.ceil(page.selected * this.state.limit) }, () => this.getData());
    };

    getProductForm = async (artwork) => {
        try {
            const response = await fetch(Routing.generate("arteo_admin_phototheque_get_product_form", { id: artwork.id }), {
                credentials: "same-origin",
                method: "GET",
            });

            if (response.status == 200) {
                const form = await response.text();

                this.initProductForm(form, artwork);
            }
        } catch (error) {
            console.log(error);
        }
    };

    initProductForm = (form, artwork) => {
        const state = this.state;

        if (form == null) {
            state.productForm = form;
        } else {
            state.productForm = parserHTML.parse(form);
        }

        state.artwork = artwork;

        this.setState(state);
    };

    initInput = () => {
        App.initSelectPicker();
    };

    submitProductForm = async () => {
        const artwork = this.state.artwork;

        try {
            const form = document.getElementById("product-form-for-phototheque-type");

            if (form) {
                const response = await fetch(Routing.generate("arteo_admin_phototheque_get_product_form", { id: artwork.id }), {
                    body: new FormData(form),
                    credentials: "same-origin",
                    method: "POST",
                });

                if (response.status == 200) {
                    const data = await response.json();

                    this.initProductForm(null, null);
                } else {
                    const form = await response.text();

                    this.initProductForm(form, artwork);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    componentDidMount() {
        this.getData();
    }

    renderList(state) {
        if (state.isLoading) {
            return (
                <div className="card-body text-center">
                    <Spinner className="primary" />
                </div>
            );
        }

        return (
            <div className="card-body text-center">
                <div className="row">
                    {state.artworks.map((artwork, index) => {
                        const carouselId = "carousel" + artwork.id + "Pictures";
                        const pictures = artwork.pictures;

                        return (
                            <div className="col-md-4 mb-3" key={index}>
                                <div className="carousel slide" id={carouselId} data-interval="false">
                                    <div className="carousel-inner">
                                        {pictures.map((picture, index2) => {
                                            return (
                                                <div className={index2 == 0 ? "carousel-item active" : "carousel-item "} key={index2}>
                                                    <img
                                                        alt={artwork.title}
                                                        className="img-fluid img-thumbnail"
                                                        onClick={() => $("#" + carouselId).carousel("next")}
                                                        src={picture}
                                                        style={{ cursor: pictures.length > 1 ? "pointer" : "auto" }}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <a
                                        className="btn btn-primary"
                                        href={Routing.generate("arteo_admin_phototheque_show_product", { id: artwork.id })}
                                        style={{ borderRadius: 0 }}
                                        target="_blank"
                                        title={Translator.trans("app.action.show")}
                                    >
                                        <i className="fas fa-eye"></i>
                                    </a>

                                    <button
                                        className="btn btn-primary"
                                        onClick={this.getProductForm.bind(this, artwork)}
                                        style={{ borderRadius: 0 }}
                                        title={Translator.trans("app.action.edit")}
                                    >
                                        <i className="fas fa-pen"></i>
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderProduct(product) {
        return <>{product.id}</>;
    }

    renderFooter(state) {
        const span = <span className="page-link">...</span>;

        return (
            <div className="card-footer">
                <div className="row">
                    <div className="col-md-8">
                        <ReactPaginate
                            activeClassName={"active"}
                            breakClassName="page-item"
                            breakLabel={span}
                            containerClassName={"pagination"}
                            marginPagesDisplayed={2}
                            nextClassName="page-item"
                            nextLabel={Translator.trans("app.pagination.next")}
                            nextLinkClassName="page-link"
                            onPageChange={this.handleOffset.bind(this)}
                            pageClassName="page-item"
                            pageCount={state.pagesTotal}
                            pageRangeDisplayed={5}
                            previousClassName="page-item"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            previousLabel={Translator.trans("app.pagination.previous")}
                        />
                    </div>
                    <div className="col-md-4">
                        <select className="selectpicker form-control float-right" onChange={this.handleLimit.bind(this)}>
                            <option value="6">6</option>
                            <option value="12">12</option>
                            <option value="24">24</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    }

    renderProductForm(state) {
        return (
            <Modal isOpen={state.productForm != null} onOpened={this.initInput.bind(this)} size="lg">
                <ModalHeader toggle={this.initProductForm.bind(this, null, null)}>{Translator.trans("app.action.edit")}</ModalHeader>
                <ModalBody>{state.productForm}</ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary mr-2" onClick={this.submitProductForm.bind(this)}>
                        {Translator.trans("arteo.form.submit")}
                    </button>
                    <button className="btn btn-secondary" onClick={this.initProductForm.bind(this, null, null)}>
                        {Translator.trans("arteo.form.cancel")}
                    </button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        const state = this.state;

        return (
            <div className="card card-border-color card-border-color-primary">
                {this.renderList(state)}
                {this.renderFooter(state)}
                {this.renderProductForm(state)}
            </div>
        );
    }
}

export default PhotothequeList;
