import React from "react";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const numberFormatter = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" });

class ProductMiniature extends React.Component {
    state = {
        product: this.props.product,
    };

    render() {
        const product = this.state.product;
        const artist = product.artist;

        return (
            <>
                <img alt={product.title} className="img-fluid img-thumbnail" src={product.image} style={{ height: 160, width: 160 }} />
                <p
                    style={{
                        border: "1px solid transparent",
                        boxShadow: "0 1px 0 rgba(0, 0, 0, 0.05)",
                        fontSize: "13px",
                        lineHeight: "28px",
                        padding: "0px 10px",
                    }}
                >
                    <a href={Routing.generate("arteo_admin_art_sales_product_show", { id: product.id })} target="_blank">
                        {artist != null && (
                            <>
                                {artist.fullName}
                                <br />
                            </>
                        )}
                        {product.title}
                        <br />
                        {numberFormatter.format(product.wantedPrice)}
                    </a>
                </p>
            </>
        );
    }
}

export default ProductMiniature;
