import Translator from "bazinga-translator";
import React from "react";
import ReactPaginate from "react-paginate";
import { Spinner } from "reactstrap";
import Routing from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const numberFormatter = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" });

class SalesTabIndicator extends React.Component {
    state = {
        data: [],
        dateStart: null,
        dateEnd: null,
        isLoading: true,
        limit: 10,
        offset: 0,
        pagesTotal: 0,
        type: "artists",
    };

    async getData() {
        this.setState({ isLoading: true });

        const state = this.state;

        try {
            const response = await fetch(
                Routing.generate("dashboard_sale_tab", {
                    type: state.type,
                    offset: state.offset,
                    limit: state.limit,
                    dateStart: state.dateStart,
                    dateEnd: state.dateEnd,
                }),
                {
                    method: "GET",
                    credentials: "same-origin",
                }
            );

            if (response.status == 200) {
                const data = await response.json();

                state.data = data.data;
                state.pagesTotal = Math.ceil(data.total / state.limit);
            }
        } catch (error) {
            console.log(error);
        }

        state.isLoading = false;

        this.setState(state);
    }

    handleDateStart(event) {
        const dateStart = event.target.value;

        if (dateStart != "" && dateStart != this.state.dateStart) {
            this.setState(
                {
                    dateStart: dateStart,
                },
                () => {
                    this.getData();
                }
            );
        }
    }

    handleDateEnd(event) {
        const dateEnd = event.target.value;

        if (dateEnd != "" && dateEnd != this.state.dateEnd) {
            this.setState(
                {
                    dateEnd: dateEnd,
                },
                () => {
                    this.getData();
                }
            );
        }
    }

    handleType(event) {
        this.setState({ type: event.target.value }, () => this.getData());
    }

    handleLimit(event) {
        this.setState({ limit: event.target.value }, () => this.getData());
    }

    handleOffset(page) {
        this.setState({ offset: Math.ceil(page.selected * this.state.limit) }, () => this.getData());
    }

    clearSearch() {
        this.setState({ dateStart: null, dateEnd: null, type: "artists" }, () => {
            $(".selectpicker").selectpicker("refresh");

            this.getData();
        });
    }

    componentDidMount() {
        this.getData();
    }

    renderSearch(state) {
        const dateStart = state.dateStart;
        const dateEnd = state.dateEnd;

        return (
            <div className="row mb-4">
                <div className="col-md-4">
                    <div className="row form-group">
                        <div className="col-form-label col-sm-4">
                            <label>{Translator.trans("arteo.admin_dashboard.search.sales.type")}</label>
                        </div>
                        <div className="col-sm-8">
                            <select className="form-control selectpicker" onChange={this.handleType.bind(this)} value={state.type}>
                                <option value="artists">{Translator.trans("arteo.admin_dashboard.sales.artists")}</option>
                                <option value="categories">{Translator.trans("arteo.admin_dashboard.sales.families")}</option>
                                <option value="customers">{Translator.trans("arteo.admin_dashboard.sales.customers")}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row form-group">
                        <div className="col-form-label col-sm-4">
                            <label>{Translator.trans("arteo.admin_dashboard.search.sales.dateStart")}</label>
                        </div>
                        <div className="col-sm-8">
                            <input
                                className="form-control datepicker"
                                data-date-format="dd/mm/yyyy"
                                onChange={() => {}}
                                onSelect={this.handleDateStart.bind(this)}
                                type="text"
                                value={dateStart != null ? dateStart : ""}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row form-group">
                        <div className="col-form-label col-sm-4">
                            <label>{Translator.trans("arteo.admin_dashboard.search.sales.dateEnd")}</label>
                        </div>
                        <div className="col-sm-8">
                            <input
                                className="form-control datepicker"
                                data-date-format="dd/mm/yyyy"
                                onChange={() => {}}
                                onSelect={this.handleDateEnd.bind(this)}
                                type="text"
                                value={dateEnd != null ? dateEnd : ""}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-12 text-center">
                    <button className="btn btn-default" onClick={this.clearSearch.bind(this)}>
                        {Translator.trans("form.search.eraseSearch")}
                    </button>
                </div>
            </div>
        );
    }

    renderBody(state) {
        if (state.isLoading) {
            return (
                <tr className="text-center">
                    <td colSpan="4">
                        <Spinner className="primary" />
                    </td>
                </tr>
            );
        }

        return state.data.map((d, index) => {
            return (
                <tr key={index}>
                    <td>{d.name}</td>
                    <td>{d.number}</td>
                    <td>{numberFormatter.format(d.ca)}</td>
                    <td>{numberFormatter.format(d.margin)}</td>
                </tr>
            );
        });
    }

    render() {
        const state = this.state;
        let type = state.type;

        if (type == "categories") {
            type = "families";
        }

        const span = <span className="page-link">...</span>;

        return (
            <div className="card card-border-color card-border-color-primary">
                <div className="card-body">
                    <div className="card-title">
                        <h5>Statistiques</h5>
                    </div>

                    {this.renderSearch(state)}

                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>{Translator.trans("arteo.admin_dashboard.sales." + type)}</th>
                                    <th>{Translator.trans("arteo.admin_dashboard.sales.number")}</th>
                                    <th>{Translator.trans("arteo.admin_dashboard.sales.ca")}</th>
                                    <th>{Translator.trans("arteo.admin_dashboard.sales.margin")}</th>
                                </tr>
                            </thead>
                            <tbody>{this.renderBody(state)}</tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-md-8">
                            <ReactPaginate
                                activeClassName={"active"}
                                breakClassName="page-item"
                                breakLabel={span}
                                containerClassName={"pagination"}
                                marginPagesDisplayed={2}
                                nextClassName="page-item"
                                nextLabel={Translator.trans("app.pagination.next")}
                                nextLinkClassName="page-link"
                                onPageChange={this.handleOffset.bind(this)}
                                pageClassName="page-item"
                                pageCount={state.pagesTotal}
                                pageRangeDisplayed={5}
                                previousClassName="page-item"
                                pageLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                previousLabel={Translator.trans("app.pagination.previous")}
                            />
                        </div>
                        <div className="col-md-4">
                            <select className="selectpicker form-control float-right" onChange={this.handleLimit.bind(this)}>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SalesTabIndicator;
