import React from "react";
import {css} from '@emotion/core';
import {FadeLoader} from "react-spinners";
import ParserHTML from "../parser/ParserHTML";
import Select from 'react-select';

import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
const routes = require("../../../../../../../js/fos_js_routes.json");
import Translator from "bazinga-translator";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ReactPaginate from "react-paginate";

const translationsConfig = require("../../../../../../../js/translations/fr.json");
Translator.fromJSON(translationsConfig);

const numberFormatter = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" });

const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
`;

let parserHTML = new ParserHTML();

export default class AcquisitionList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      itemPerPage: 10,
      itemOffsetPage: 0,
      nbTotalItemsPage: 10,
      loadingItems: false,
      items: []
    };
  }

  async componentDidMount () {
    this.loadItems();
  }

  async loadItems() {
    this.setState({
      loadingItems: true
    });

    let url = Routing.generate('arteo_admin_customer_api_get_products_acquisition', {
      id: this.props.customerId,
      limit: this.state.itemPerPage,
      offset: this.state.itemOffsetPage
    });

    try {
      let response = await fetch(url, {
        method: "GET",
        credentials: "same-origin"
      });

      if (response.status == 200) {
        let datas = await response.json();

        this.setState({
          items: JSON.parse(datas.artworks),
          nbTotalItemsPage: Math.ceil(datas.nbArtworks / this.state.itemPerPage),
          loadingItems: false
        });
      }
    } catch(error) {
      console.log(error);
    }
  }

  async handleClickPages(page) {
    let offset = Math.ceil(page.selected * this.state.itemPerPage);

    this.setState({
      itemOffsetPage: offset
    }, () => {
      this.loadItems();
    });
  }

  render() {
    let lines = this.state.items.map((item, key) => {

      let styleState = {backgroundColor: item.artwork.state.color};
      let urlExportPdfProduct = Routing.generate("arteo_admin_art_product_pdf", {id: item.artwork.id});

      let purchases = item.artwork.purchases.map((purchaseItem, key) => {
         return(
              <li className={key == 0 ? 'latest' : ''} key={key}>
                <div className="timeline-compact-date">
                  <a href={purchaseItem.url}>{purchaseItem.date}</a> <a href={purchaseItem.pdf} target="_blank"><i className="fas fa-file-pdf"></i></a>
                </div>
                <div className="timeline-compact-title">
                  <span className="label label-black">
                    {purchaseItem.mode}
                  </span>
                </div>
              </li>
         );
      });

      return (
        <tr key={key}>
          <td width="200">
            <img src={item.artworkImage} alt={item.artwork.title} />
            <br />
            <a href={item.artworkLink}>{item.artwork.title}</a>
          </td>
          <td>
            <a href={item.artwork.artist_link}>{item.artwork.artist}</a>
          </td>
          <td>
            {item.artwork.category}
          </td>
          <td>
            {item.artwork.code}
          </td>
          <td>
            {item.artwork.bookPoliceNumber}
          </td>
          <td>
            {numberFormatter.format(item.artwork.acquisitionPrice)}
          </td>
          <td>
            {item.artwork.saleInfo ?
              (
                <>
                  {numberFormatter.format(item.artwork.saleInfo.amount)} <a href={item.artwork.saleInfo.bill_pdf}><i className="fas fa-file-pdf"></i></a>
                  <br />
                  <a href={item.artwork.saleInfo.bill_url}>
                    Facture {item.artwork.saleInfo.bill_code}
                  </a>
                </>
              )
              :
              ''
            }
          </td>
          <td className="text-center">
            <span className="label label-black">{item.artwork.acquisitionMode}</span>
            <br /> <br />
            <span className="label" style={styleState}>{item.artwork.state.name}</span>
          </td>
          <td>
            <ul className="timeline-compact">
              {purchases}
            </ul>
          </td>
        </tr>
      );
    });

    return(
      <>
        <div className="card card-border-color card-border-color-primary">
          <div className="card-body">
            <div className="card-title">
              <h5>Oeuvres acquise par la galerie :</h5>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover datatable">
                <thead>
                <tr>
                  <th>
                    {Translator.trans('arteo.admin_art.product.search.table.title')}
                  </th>
                  <th>
                    {Translator.trans('arteo.admin_art.product.search.table.artist')}
                  </th>
                  <th>
                    {Translator.trans('arteo.admin_art.product.search.table.category')}
                  </th>
                  <th>
                    {Translator.trans('arteo.admin_art.product.search.table.code')}
                  </th>
                  <th>
                    {Translator.trans('arteo.admin_art.product.search.table.bookPoliceNumber')}
                  </th>
                  <th>
                    {Translator.trans('arteo.admin_art.product.search.table.acquisitionPrice')}
                  </th>
                  <th>
                    Vendu
                  </th>
                  <th colSpan={1}>

                  </th>
                  <th>
                    Acquisitions
                  </th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.loadingItems === true ?
                    (
                      <tr>
                        <td colSpan={3}>
                          <FadeLoader
                            css={override}
                            sizeUnit={"px"}
                            size={500}
                            color={'#004788'}
                            loading={this.state.loadingItems}
                          />
                        </td>
                      </tr>
                    )
                    :
                    lines
                }
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col-md-8">
                <ReactPaginate
                  breakClassName="page-item"
                  breakLabel={
                    <span className="page-link">
                          ...
                        </span>
                  }
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  pageCount={this.state.nbTotalItemsPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handleClickPages.bind(this)}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}