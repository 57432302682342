import React from "react";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ParserHTML from "../parser/ParserHTML";
import FlashNotification from "../notification/FlashNotification";

Routing.setRoutingData(require("../../../../../../../js/fos_js_routes.json"));
moment.locale("fr");

const parserHTML = new ParserHTML();
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);
const messages = {
    allDay: "Journée",
    previous: "Précédent",
    next: "Suivant",
    today: "Aujourd'hui",
    month: "Mois",
    week: "Semaine",
    day: "Jour",
    agenda: "Agenda",
    date: "date",
    time: "heure",
    event: "événement",
    showMore: total => `+ ${total} événement(s) supplémentaire(s)`
};

export default class PersonalCalendar extends React.Component {
    state = {
        event: null,
        events: [],
        modalContent: "",
        notification: undefined,
        open: false
    };

    closeModal = () => {
        this.setState(
            {
                event: null,
                open: false
            },
            () => this.getEvents()
        );
    };

    newEvent = async event => {
        try {
            let url = Routing.generate("calendar_events_new");

            let params = {
                eventStart: moment(event.slots[0]).format("DD-MM-YYYY-HH-mm")
            };

            url = url.concat(
                "?",
                Object.entries(params)
                    .map(([key, val]) => `${key}=${val}`)
                    .join("&")
            );

            let response = await fetch(url, {
                method: "GET",
                credentials: "same-origin"
            });

            if (response.status == 200) {
                let form = await response.text();

                this.setState({
                    action: "new",
                    modalContent: parserHTML.parse(form),
                    open: true
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    editEvent = async event => {
        try {
            let response = await fetch(Routing.generate("calendar_events_edit", { id: event.id }), {
                method: "GET",
                credentials: "same-origin"
            });

            if (response.status == 200) {
                let form = await response.text();

                this.setState({
                    action: "edit",
                    event: event,
                    modalContent: parserHTML.parse(form),
                    open: true
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    moveOrReziseEvent = e => {
        let event = e.event;

        event.start = e.start;
        event.end = e.end;

        let events = this.state.events.map(ev => {
            return ev === event ? event : ev;
        });

        this.setState({ events: events }, () => this.updateEvent(event));
    };

    updateEvent = async event => {
        try {
            let url = Routing.generate("calendar_events_update", { id: event.id });

            let params = {
                eventStart: moment(event.start).format("DD-MM-YYYY-HH-mm"),
                eventEnd: moment(event.end).format("DD-MM-YYYY-HH-mm")
            };

            url = url.concat(
                "?",
                Object.entries(params)
                    .map(([key, val]) => `${key}=${val}`)
                    .join("&")
            );
            let response = await fetch(url, {
                method: "PUT",
                credentials: "same-origin"
            });

            if (response.status == 201) {
                let data = await response.json();

                this.setState({
                    notification: { message: data.notification.message, type: data.notification.type }
                });

                this.setState({
                    notification: undefined
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    removeEvent = async event => {
        try {
            let response = await fetch(Routing.generate("calendar_events_remove", { id: event.id }), {
                method: "POST",
                credentials: "same-origin"
            });

            if (response.status == 201) {
                let data = await response.json();

                this.setState(
                    {
                        modalContent: "",
                        notification: { message: data.notification.message, type: data.notification.type },
                        open: false
                    },
                    () => this.getEvents()
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    submitEventForm = async () => {
        let form = document.getElementById("event_form");
        let url = Routing.generate("calendar_events_new");

        if (this.state.action === "edit") {
            url = Routing.generate("calendar_events_edit", { id: this.state.event.id });
        }

        try {
            let response = await fetch(url, {
                method: "POST",
                body: new FormData(form),
                credentials: "same-origin"
            });

            if (response.status == 201) {
                let data = await response.json();

                this.setState(
                    {
                        modalContent: "",
                        notification: { message: data.notification.message, type: data.notification.type },
                        open: false
                    },
                    () => this.getEvents()
                );
            } else {
                let form = await response.text();

                this.setState({
                    modalContent: parserHTML.parse(form)
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    eventStyleGetter = (event, start, end, isSelected) => {
        let style = {
            backgroundColor: "#b0b0b0",
            borderRadius: "0px",
            border: "0px",
            display: "block"
        };

        return {
            style: style
        };
    };

    getEvents = async () => {
        try {
            let response = await fetch(Routing.generate("calendar_events_user"), {
                method: "GET",
                credentials: "same-origin"
            });

            if (response.status == 200) {
                let data = await response.json();

                for (let event of data.events) {
                    event.allDay = false;
                    event.start = moment(event.start).toDate();
                    event.end = moment(event.end).toDate();

                    if (event.isFromGoogle) {
                        event.title = (
                            <div>
                                <span className="fab fa-google"></span> {event.title}
                            </div>
                        );
                    }
                }

                this.setState({ events: data.events, notification: undefined });
            }
        } catch (error) {
            console.log(error);
        }
    };

    componentDidMount() {
        this.getEvents();

        $(document).on("submit", "#event_form", async form => {
            form.preventDefault();
        });
    }

    render() {
        let event = this.state.event;

        return (
            <div>
                <div>
                    <FlashNotification notification={this.state.notification} />
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="calendar-container" style={{ padding: 20, height: "90vh" }}>
                            <DnDCalendar
                                defaultView="week"
                                eventPropGetter={this.eventStyleGetter}
                                events={this.state.events}
                                max={new Date(2017, 10, 0, 20, 0, 0)}
                                messages={messages}
                                min={new Date(2017, 10, 0, 7, 0, 0)}
                                localizer={localizer}
                                onSelectEvent={this.editEvent}
                                onSelectSlot={this.newEvent}
                                onEventDrop={this.moveOrReziseEvent}
                                onEventResize={this.moveOrReziseEvent}
                                resizable
                                selectable
                            />

                            <Modal size="lg" isOpen={this.state.open}>
                                <ModalHeader toggle={this.closeModal.bind(this)}>Événement</ModalHeader>
                                <ModalBody>{this.state.modalContent}</ModalBody>
                                <ModalFooter>
                                    <button form="event_form" className="btn btn-primary" onClick={this.submitEventForm.bind(this)}>
                                        Envoyer
                                    </button>
                                    {event && event.id && (
                                        <button className="btn btn-danger" onClick={this.removeEvent.bind(this, event)}>
                                            Effacer
                                        </button>
                                    )}
                                    <button className="btn btn-secondary" onClick={this.closeModal.bind(this)}>
                                        Annuler
                                    </button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
