import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import SalesProduct from "./components/artwork/SalesProduct";
import EventList from "./components/calendar/EventList";
import PersonalCalendar from "./components/calendar/PersonalCalendar";
import ModalNote from "./components/note/ModalNote";
import NoteList from "./components/note/NoteList";
import ProspectionForArtwork from "./components/artwork/ProspectionForArtwork";
import ProspectionForCustomer from "./components/customer/ProspectionForCustomer";
import ProductViewer from "./components/productViewer/ProductViewer";
import ProductViewerCreator from "./components/productViewer/ProductViewerCreator";
import productStore from "./stores/productStore";
import NotificationList from "./components/notification/NotificationList";
import CarouselProducts from "./components/exposition/CarouselProducts";
import ProductList from "./components/exposition/ProductList";
import MediaList from "./components/exposition/MediaList";
import ExpositionViewList from "./components/exposition/ExpositionViewList";
import PublicationList from "./components/exposition/PublicationList";
import PressFileList from "./components/exposition/PressFileList";
import AcquisitionList from "./components/customer/AcquisitionList";
import DepositList from "./components/customer/DepositList";
import SaleList from "./components/customer/SaleList";
import PaymentList from "./components/payment/PaymentList";
import SalesTabIndicator from "./components/sale/indicator/SalesTabIndicator";
import PhotothequeList from "./components/artwork/PhotothequeList";
import ImageDropzone from "./components/core/ImageDropzone";

/**
 * Redering Calendar
 */
const calendar = document.getElementById("calendar-component");

if (calendar) {
    render(<PersonalCalendar />, calendar);
}

/**+
 * Rendering component React NoteModal
 */
var documentNoteModal = document.getElementById("react-note-modal-component");

if (documentNoteModal) {
    render(<ModalNote />, documentNoteModal);
}

/**
 * Rendering component React NoteList
 */
var documentNotesList = document.getElementById("react-note-list-component");

if (documentNotesList) {
    render(<NoteList customerId={documentNotesList.dataset.customerId} />, documentNotesList);
}

/**
 * Rendering component React NoteList
 */
var documentEventsList = document.getElementById("react-event-list-component");

if (documentEventsList) {
    render(<EventList customerId={documentEventsList.dataset.customerId} />, documentEventsList);
}

/**
 * Rendering component React Prospection
 */
var prospectionContainerArtwork = document.getElementById("container-react-prospection-artwork");

if (prospectionContainerArtwork) {
    render(<ProspectionForArtwork artworkId={prospectionContainerArtwork.dataset.artworkId} />, prospectionContainerArtwork);
}

/**
 * Rendering component React Prospection
 */
var prospectionContainerCustomer = document.getElementById("container-react-prospection-customer");

if (prospectionContainerCustomer) {
    render(<ProspectionForCustomer customerId={prospectionContainerCustomer.dataset.customerId} />, prospectionContainerCustomer);
}

/**
 * Rendering component React SalesProduct
 */
const salesProduct = document.getElementById("container-react-sales-product");

if (salesProduct) {
    render(
        <Provider store={productStore}>
            <SalesProduct />
        </Provider>,
        salesProduct
    );
}

/**
 * Rendering component React ProductViewerCreator
 */
const productViewerCreator = document.getElementById("container-react-product-viewer-creator");

if (productViewerCreator) {
    render(
        <Provider store={productStore}>
            <ProductViewerCreator />
        </Provider>,
        productViewerCreator
    );
}

/**
 * Rendering component React ProductViewer
 */
const productViewer = document.getElementById("container-react-product-viewer");

if (productViewer) {
    render(<ProductViewer productViewerId={productViewer.dataset.productViewerId} />, productViewer);
}

/**
 * Rendering component React NotificationList
 */
const notificationListElements = Array.prototype.slice.call(document.getElementsByClassName("container-react-notification-list"));

notificationListElements.forEach((element) => {
    render(
        <NotificationList
            entityClassName={element.dataset.entityClassName}
            entityId={element.dataset.entityId}
            recipientClassName={element.dataset.recipientClassName}
        />,
        element
    );
});

const expoProducts = document.getElementById("component-expo-products");

if (expoProducts) {
    render(<ProductList expoId={expoProducts.dataset.expoId} />, expoProducts);
}

const expoMedias = document.getElementById("component-expo-medias");

if (expoMedias) {
    render(<MediaList idExpo={expoMedias.dataset.idExpo} />, expoMedias);
}

const expoViews = document.getElementById("component-expo-views");

if (expoViews) {
    render(<ExpositionViewList idExpo={expoViews.dataset.idExpo} />, expoViews);
}

const expoPublications = document.getElementById("component-expo-publications");

if (expoPublications) {
    render(<PublicationList idExpo={expoPublications.dataset.idExpo} />, expoPublications);
}

const expoPressFiles = document.getElementById("component-expo-press-files");

if (expoPressFiles) {
    render(<PressFileList idExpo={expoPressFiles.dataset.idExpo} />, expoPressFiles);
}

const expoCarouselProducts = document.getElementById("react-expo-carousel-products");

if (expoCarouselProducts) {
    render(<CarouselProducts expoId={expoCarouselProducts.dataset.expoId} />, expoCarouselProducts);
}

const containerCustomerAcquisition = document.getElementById("react-customer-acquisition-component");
if (containerCustomerAcquisition) {
    render(<AcquisitionList customerId={containerCustomerAcquisition.dataset.customerId} />, containerCustomerAcquisition);
}

const containerCustomerDeposit = document.getElementById("react-customer-deposit-component");
if (containerCustomerDeposit) {
    render(<DepositList customerId={containerCustomerDeposit.dataset.customerId} />, containerCustomerDeposit);
}

const containerCustomerSale = document.getElementById("react-customer-sale-component");
if (containerCustomerSale) {
    render(<SaleList customerId={containerCustomerSale.dataset.customerId} />, containerCustomerSale);
}

/**
 * Rendering component React PaymentList
 */
var documentPaymentList = document.getElementById("react-payment-list-component");

if (documentPaymentList) {
    render(<PaymentList isGranted={documentPaymentList.dataset.isGranted} />, documentPaymentList);
}

/**
 * Rendering component React SalesTabIndicator
 */
const salesTabIndicator = document.getElementById("react-sales-tab-indicator");

if (salesTabIndicator) {
    render(<SalesTabIndicator />, salesTabIndicator);
}

/**
 * Rendering component React PhotothequeList
 */
const photothequeList = document.getElementById("react-phototheque-list");

if (photothequeList) {
    render(<PhotothequeList />, photothequeList);
}

/**
 * Rendering component React PhotothequeList
 */
const customDropzone = document.getElementById("react-image-dropzone");

if (customDropzone) {
    render(
        <ImageDropzone
            addRoute={customDropzone.dataset.addRoute}
            collection={customDropzone.dataset.collection}
            field={customDropzone.dataset.field}
            redirectRoute={customDropzone.dataset.redirectRoute}
            title={customDropzone.dataset.title}
        />,
        customDropzone
    );
}
