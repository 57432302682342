import React from "react";
import {css} from '@emotion/core';
import {FadeLoader} from "react-spinners";
import ParserHTML from "../parser/ParserHTML";
import Select from 'react-select';


import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
const routes = require("../../../../../../../js/fos_js_routes.json");
import Translator from "bazinga-translator";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ReactPaginate from "react-paginate";

const translationsConfig = require("../../../../../../../js/translations/fr.json");
Translator.fromJSON(translationsConfig);

const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
`;

let parserHTML = new ParserHTML();

export default class ProspectionForArtwork extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      flashMessages: [],
      customers: [],
      customersChecked: [],
      loadingCustomers: false,
      artworkId: this.props.artworkId,
      customerPerPage: 10,
      customerOffsetPage: 0,
      nbTotalCustomerPage: 10,
      artistFilter: true,
      categoryFilter: true,
      artistAlreadySaledFilter: true,
      keywordsFilter: [],
      keywordsOptions: [],
      artwork: null,
      modalForm: null,
      modalIsOpen: false,
    };
  }

  async componentDidMount () {
    this.loadArtworkInfos();
  }

  initInput = () => {
    App.initSelectPicker();
    App.initDatePicker();
    App.initCkeditor();
  }

  loadCustomers = async () => {
    this.setState({
      loadingCustomers: true
    });

    let url = Routing.generate('arteo_admin_art_prospection_list', {
      limit: this.state.customerPerPage,
      offset: this.state.customerOffsetPage
    });

    url += '?artworkId=' + this.state.artworkId;

    if(!this.state.artistFilter) {
      url += '&filterArtist=0';
    }

    if(!this.state.categoryFilter) {
      url += '&filterCategory=0';
    }

    if(!this.state.artistAlreadySaledFilter) {
      url += '&filterArtistAlreadySaled=0';
    }

    if(this.state.keywordsFilter.length > 0) {
      url += '&keywords=' + [...this.state.keywordsFilter.map((item,key) => { return item.value })].join(',');
    }

    try {
      let response = await fetch(url, {
        method: "GET",
        credentials: "same-origin"
      });

      if (response.status == 200) {
        let datas = await response.json();
        this.setState({
          customers: JSON.parse(datas.customers),
          nbTotalCustomerPage: Math.ceil(datas.nbCustomers / this.state.customerPerPage),
          loadingCustomers: false
        });
      }
    } catch(error) {
      console.log(error);
    }
  }

  loadArtworkInfos = async() => {
    let url = Routing.generate('arteo_admin_art_prospection_artwork_info', {id: this.props.artworkId});

    try {
      let response = await fetch(url, {
        method: "GET",
        credentials: "same-origin"
      });

      if (response.status == 200) {
        let datas = await response.json();
        let product = JSON.parse(datas.product);

        this.setState({
          product: product,
          keywordsFilter: [...product.keywords.map((item, key) => { return {value: item.id, label: item.name}; })],
          keywordsOptions: [...product.keywords.map((item, key) => { return {value: item.id, label: item.name}; })],
          loadingCustomers: false
        }, () => this.loadCustomers());
      }
    } catch(error) {
      console.log(error);
    }

  }

  async handleClickPages(page) {
    let offset = Math.ceil(page.selected * this.state.customerPerPage);

    this.setState({
      customerOffsetPage: offset
    }, () => {
      this.loadCustomers();
    })
  }

  notifyCustomer = async (customerId) => {
    this.getModalForm(customerId);
  }

  notifyGlobal = async () => {
   this.getModalForm();
  }

  handleModal = (open, form = null) => {
    if (form != null) {
      form = parserHTML.parse(form);
    }

    this.setState({
      modalForm: form,
      modalIsOpen: open,
    });
  };

  getModalForm = async (customerId = null) => {
    let customers = "";

    if(customerId !== null) {
      customers = customerId;
    } else {
      customers = this.state.customersChecked.join(',');
    }

    try {
      const response = await fetch(Routing.generate("arteo_admin_art_product_prospection_send", { id: this.props.artworkId }) + "?customersChecked=" + customers, {
        method: "GET",
        credentials: "same-origin",
      });

      if (response.status == 200) {
        const form = await response.text();

        this.handleModal(true, form);
      }
    } catch (error) {
      console.log(error);
    }
  };

  submitModalForm = async () => {
    try {
      $("#product_viewer_customer_content").val($(".ck-content").html());

      const response = await fetch(Routing.generate("arteo_admin_art_product_prospection_send", { id: this.props.artworkId }), {
        body: new FormData(document.getElementById("product-viewer-customer-form")),
        credentials: "same-origin",
        method: "POST",
      });

      if (response.status == 201) {
        this.handleModal(false);

        const flashMessages = this.state.flashMessages;

        flashMessages.push({ type: "success", message: Translator.trans("arteo.admin_art.product_viewer_customer.flash.send.success") });

        this.setState({ flashMessages: flashMessages });
      } else {
        const form = await response.text();

        this.handleModal(true, form);
      }
    } catch (error) {
      console.log(error);
    }
  };

  addCheckCustomer(customerId)
  {
    let customersChecked = this.state.customersChecked;

    customersChecked.push(customerId);

    this.setState({
      customersChecked: customersChecked
    });
  }

  filterArtist() {
    let filter = this.state.artistFilter;

    this.setState({
      artistFilter: !filter
    }, this.loadCustomers);
  }

  filterArtistAlreadySaled() {
    let filter = this.state.artistAlreadySaledFilter;

    this.setState({
      artistAlreadySaledFilter: !filter
    }, this.loadCustomers);
  }

  filterCategory() {
    let filter = this.state.categoryFilter;

    this.setState({
      categoryFilter: !filter
    }, this.loadCustomers);
  }

  selectKeywords = selectedOption => {

    this.setState({
      keywordsFilter: selectedOption == null ? [] : selectedOption
    }, this.loadCustomers);
  }

  removeFlashMessage = (flashMessage) => {
    const flashMessages = [];

    this.state.flashMessages.forEach((fm) => {
      if (fm !== flashMessage) {
        flashMessages.push(fm);
      }
    });

    this.setState({
      flashMessages: flashMessages,
    });
  };

  renderFlashMessages() {
    const flashMessages = this.state.flashMessages;

    return flashMessages.map((flashMessage, index) => {
      return (
        <div className={"alert alert-" + flashMessage.type} key={index} role="alert">
          {flashMessage.message}

          <button aria-label="Close" className="close" onClick={this.removeFlashMessage.bind(this, flashMessage)} type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    });
  }

  render() {
    let listCustomers = this.state.customers.map((item, key) => {
      return (
        <tr key={key}>
          <td>
            <input type="checkbox" onClick={this.addCheckCustomer.bind(this, item.customer.id)} />
          </td>
          <td>
            <a href={item.link}>{item.customer.name}</a>
          </td>
          <td>{item.customer.email}</td>
          <td>{item.customer.phone}</td>
          <td>
            {item.isNotify ? (<span className="badge badge-success" data-toggle="tooltip" data-placement="bottom" data-original-title={item.notifyDate} ><i
              className="fas fa-check-circle"></i> {item.notifyDate}</span>) : ''}
            <button className="btn btn-primary ml-2" onClick={this.notifyCustomer.bind(this, item.customer.id)} data-toggle="tooltip" title={Translator.trans("arteo.admin_art.product.show.prospection.table.notify")} data-original-title={Translator.trans("arteo.admin_art.product.show.prospection.table.notify")}>
              <i className="fas fa-paper-plane"></i>
            </button>
          </td>
        </tr>
      );
    });

    let modal = (
        <Modal isOpen={this.state.modalIsOpen} size="xl" onOpened={this.initInput.bind(this)} toggle={this.handleModal.bind(this, false)}>
          <ModalHeader toggle={this.handleModal.bind(this, false)}>
            {Translator.trans("arteo.admin_art.prospection.product.send")}
          </ModalHeader>
          <ModalBody>{this.state.modalForm}</ModalBody>
          <ModalFooter>
            <button className="btn btn-default" onClick={this.submitModalForm.bind(this)}>
              {Translator.trans("app.form.submit")}
            </button>
            <button className="btn btn-secondary" onClick={this.handleModal.bind(this, false)}>
              {Translator.trans("app.form.cancel")}
            </button>
          </ModalFooter>
        </Modal>
    );

    let flashMessages = this.renderFlashMessages();

    return(
      <>
        {flashMessages}
      <div>
        <div className="card">
          <div className="card-body">
            <div className="card-title">
              {Translator.trans("arteo.admin_art.product.show.prospection.filter")}
            </div>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="select-keywords">Mots clés :</label>
                <Select isMulti value={this.state.keywordsFilter} onChange={this.selectKeywords} options={this.state.keywordsOptions}/>
              </div>
              <div className="col-md-3">
                <label htmlFor="artist">Artiste :</label>
                <br />
                <input type="checkbox" id="artist" onChange={this.filterArtist.bind(this)} checked={this.state.artistFilter} /> { this.state.product ? this.state.product.artist.name : ''}
              </div>
              <div className="col-md-2">
                <label htmlFor="category">Catégorie :</label> <br />
                <input type="checkbox" id="category" onChange={this.filterCategory.bind(this)} checked={this.state.categoryFilter} /> {this.state.product ? this.state.product.category.name : ''}
              </div>

              <div className="col-md-3">
                <label htmlFor="category">Clients ayant déjà acheté cet artiste</label>
                <input type="checkbox" id="category" onChange={this.filterArtistAlreadySaled.bind(this)} checked={this.state.artistAlreadySaledFilter}/>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-border-color card-border-color-primary">
          <div className="card-body">
            <div className="card-title">
              {Translator.trans("arteo.admin_art.product.show.prospection.title")}
            </div>
            <div className="dropdown mt-2 mb-2">
              <button className="btn btn-default dropdown-toggle" id="table-action-selection" type="button"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {Translator.trans('arteo.list.actions-list')}
              </button>
              <div className="dropdown-menu" aria-labelledby="table-action-selection">
                <button className="dropdown-item btn btn-link" onClick={this.notifyGlobal.bind(this)}>
                  <i className="fa fa-paper-plane mr-1"></i> {Translator.trans('arteo.admin_art.product.show.prospection.notify_global')}
                </button>
              </div>
            </div>
            <table className="table table-hover table-striped">
              <thead>
              <tr>
                <th></th>
                <th>
                  {Translator.trans("arteo.admin_art.product.show.prospection.table.name")}
                </th>
                <th>
                  {Translator.trans("arteo.admin_art.product.show.prospection.table.mail")}
                </th>
                <th>
                  {Translator.trans("arteo.admin_art.product.show.prospection.table.phone")}
                </th>
                <th>
                  {Translator.trans("arteo.admin_art.product.show.prospection.table.actions")}
                </th>
              </tr>
              </thead>
              <tbody>
              {
                this.state.loadingCustomers === true ?
                  (
                    <tr>
                      <td colSpan={3}>
                        <FadeLoader
                          css={override}
                          sizeUnit={"px"}
                          size={500}
                          color={'#004788'}
                          loading={this.state.loadingCustomers}
                        />
                      </td>
                    </tr>
                  )
                  :
                  listCustomers
              }
              </tbody>
            </table>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col-md-8">
                <ReactPaginate
                  breakClassName="page-item"
                  breakLabel={
                    <span className="page-link">
                        ...
                      </span>
                  }
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  pageCount={this.state.nbTotalCustomerPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handleClickPages.bind(this)}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
        {modal}
      </div>
        </>
    );
  }
}