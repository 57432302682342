import React from "react";
import {css} from '@emotion/core';
import {FadeLoader} from "react-spinners";
import ParserHTML from "../parser/ParserHTML";
import Select from 'react-select';

import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
const routes = require("../../../../../../../js/fos_js_routes.json");
import Translator from "bazinga-translator";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ReactPaginate from "react-paginate";

const translationsConfig = require("../../../../../../../js/translations/fr.json");
Translator.fromJSON(translationsConfig);
const numberFormatter = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" });

const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
`;

let parserHTML = new ParserHTML();

export default class AcquisitionList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      itemPerPage: 10,
      itemOffsetPage: 0,
      nbTotalItemsPage: 10,
      loadingItems: false,
      items: []
    };
  }

  async componentDidMount () {
    this.loadItems();
  }

  async loadItems() {
    this.setState({
      loadingItems: true
    });

    let url = Routing.generate('arteo_admin_customer_api_get_sales', {
      id: this.props.customerId,
      limit: this.state.itemPerPage,
      offset: this.state.itemOffsetPage
    });

    try {
      let response = await fetch(url, {
        method: "GET",
        credentials: "same-origin"
      });

      if (response.status == 200) {
        let datas = await response.json();

        this.setState({
          items: JSON.parse(datas.sales),
          nbTotalItemsPage: Math.ceil(datas.nbSales / this.state.itemPerPage),
          loadingItems: false
        });
      }
    } catch(error) {
      console.log(error);
    }
  }

  async handleClickPages(page) {
    let offset = Math.ceil(page.selected * this.state.itemPerPage);

    this.setState({
      itemOffsetPage: offset
    }, () => {
      this.loadItems();
    });
  }

  render() {
    let lines = this.state.items.map((item, key) => {
      let labelBill = "label-default";

      if(item.bill.currentPlace == 'sent') {
        labelBill = 'label-blue';
      } else if(item.bill.currentPlace == 'paid') {
        labelBill = 'label-green';
      } else if(item.bill.currentPlace == 'partialPaid') {
        labelBill = 'label-orange';
      }

      let billInfo = (
        <>
          <a href={item.bill.bill_url}>{item.bill.code}</a> <span className={"label " + labelBill}>{Translator.trans('arteo.admin_bill.bill.workflow.place.' + item.bill.currentPlace)}</span>
          <br />
            <b>{numberFormatter.format(item.bill.amountTTC)}</b> <a href={item.bill.bill_pdf}><i className="fas fa-file-pdf"></i></a>
        </>
      );

      return (
        <tr key={key}>
          <td>
            <a href={item.sale_link}>{item.author}</a>
          </td>
          <td width="200">
            <img src={item.artworkImage} alt={item.title} />
            <br />
            <a href={item.artworkLink}>{item.title}</a>
          </td>
          <td>
            <a href={item.artist_link}>{item.artist}</a>
          </td>
          <td>
            {item.category}
          </td>
          <td>
            {item.code}
          </td>
          <td>
            {item.bookPoliceNumber !== undefined ? item.bookPoliceNumber : ''}
          </td>
          <td>
            {item.date}
          </td>
          <td className="text-center">
            <span className="label label-black">{item.acquisitionMode}</span>
          </td>
          <td>
            {billInfo}
          </td>
        </tr>
      );
    });

    return(
      <>
        <div className="card card-border-color card-border-color-primary">
          <div className="card-body">
            <div className="card-title">
              <h5>Oeuvres vendues par la galerie :</h5>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover datatable">
                <thead>
                <tr>
                  <th>
                    {Translator.trans('arteo.admin_business.sale.search.form.galleryUser')}
                  </th>
                  <th>
                    {Translator.trans('arteo.admin_art.product.search.table.title')}
                  </th>
                  <th>
                    {Translator.trans('arteo.admin_art.product.search.table.artist')}
                  </th>
                  <th>
                    {Translator.trans('arteo.admin_art.product.search.table.category')}
                  </th>
                  <th>
                    {Translator.trans('arteo.admin_art.product.search.table.code')}
                  </th>
                  <th>
                    {Translator.trans('arteo.admin_art.product.search.table.bookPoliceNumber')}
                  </th>
                  <th>
                    Date
                  </th>
                  <th>
                    Mode d'acquisition
                  </th>
                  <th>
                    Facture
                  </th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.loadingItems === true ?
                    (
                      <tr>
                        <td colSpan={3}>
                          <FadeLoader
                            css={override}
                            sizeUnit={"px"}
                            size={500}
                            color={'#004788'}
                            loading={this.state.loadingItems}
                          />
                        </td>
                      </tr>
                    )
                    :
                    lines
                }
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col-md-8">
                <ReactPaginate
                  breakClassName="page-item"
                  breakLabel={
                    <span className="page-link">
                          ...
                        </span>
                  }
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  pageCount={this.state.nbTotalItemsPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handleClickPages.bind(this)}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}