import React, { Component } from "react";

export default class InputField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value || ""
        };
    }

    onChange(e) {
        this.setState({
            value: e.target.value
        });
    }

    render() {
        return (
            <input
                type={this.props.type}
                id={this.props.id}
                name={this.props.name}
                placeholder={this.props.placeholder}
                className={this.props.class}
                value={this.state.value}
                onChange={this.onChange.bind(this)}
                readOnly={false}
                disabled={false}
                style={this.props.style}
                redirectroute={this.props.redirectroute}
                data-prototype={this.props["data-prototype"]}
                required={this.props.required}
            />
        );
    }
}
