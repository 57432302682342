import { Carousel } from "3d-react-carousal";
import React from "react";
import { Spinner } from "reactstrap";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
const numberFormatter = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" });

const formatNumber = (number) => {
    if(number == undefined) {
        return 0;
    }

    const result = number.split(".");

    if (result.length > 1 && result[1] == "00") {
        number = parseInt(number);
    }

    return number;
};

export default class CarouselProducts extends React.Component {
    state = {
        expoId: this.props.expoId,
        loading: false,
        products: [],
        view: "carousel",
    };

    loadProducts = async () => {
        this.setState({
            loading: true,
        });

        const state = this.state;

        try {
            const response = await fetch(Routing.generate("arteo_admin_art_exposition_api_front_products", { id: state.expoId }), {
                method: "GET",
                credentials: "same-origin",
            });

            if (response.status === 200) {
                state.products = JSON.parse(await response.json());
            }
        } catch (error) {
            console.log(error);
        }

        state.loading = false;

        this.setState(state);
    };

    componentDidMount() {
        this.loadProducts();
    }

    handleView(view) {
        this.setState({ view: view });
    }

    renderArtworks(state) {
        if (state.view === "carousel") {
            const slides = this.state.products.map((item, key) => {
                return (
                    <div className="card text-center" key={key}>
                        <div className="card-body">
                            <div className="p-3">
                                <a href={item.img_original} data-lightbox={"exposition-" + key}>
                                    <img src={item.img_slide} alt={item.title} />
                                </a>
                                <br />
                                <br />
                                <h4>{item.title}</h4>
                                <p className="font-weight-bold">{item.artist}</p>
                                <em>
                                    {formatNumber(item.mesure.height)} x {formatNumber(item.mesure.width)} cm
                                </em>
                                {
                                    item.printPrice == true ?
                                      <p className="mt-2">
                                          <strong>{numberFormatter.format(item.price)}</strong>
                                      </p>
                                    :
                                      ''
                                }
                            </div>
                        </div>
                    </div>
                );
            });

            return <Carousel slides={slides} interval={1000} />;
        }

        return (
            <div className="row text-center">
                {this.state.products.map((item, key) => {
                    return (
                        <div className="col-md-3 mb-5" key={key}>
                            <a href={item.img_original} data-lightbox={"exposition-" + key}>
                                <img src={item.img} alt={item.title} />
                            </a>
                            <br />
                            <br />
                            <h5>{item.title}</h5>
                            <p>{item.artist}</p>
                            <em>
                                {formatNumber(item.mesure.height)} x {formatNumber(item.mesure.width)} cm
                            </em>
                            {
                                item.printPrice == true ?
                                  <p className="mt-2">
                                      <strong>{numberFormatter.format(item.price)}</strong>
                                  </p>
                                  :
                                  ''
                            }
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        const state = this.state;

        return (
            <div className="exposition-container-carousel">
                <div className="row">
                    <div className="col-md-12 text-right mb-2">
                        <button className="btn btn-primary mr-2" onClick={this.handleView.bind(this, "carousel")}>
                            <i className="fas fa-film"></i>
                        </button>
                        <button className="btn btn-primary" onClick={this.handleView.bind(this, "grid")}>
                            <i className="fas fa-th-large"></i>
                        </button>
                    </div>
                </div>

                {state.loading === true ? <Spinner className="primary" /> : this.renderArtworks(state)}
            </div>
        );
    }
}
