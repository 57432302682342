const initialState = {
    productToAdd: null,
    productViewer: null,
};

const productReducer = (state = initialState, action) => {
    let nextState;

    switch (action.type) {
        case "setProductToAdd":
            nextState = {
                ...state,
                productToAdd: action.value,
            };

            break;
        case "setProductViewer":
            nextState = {
                ...state,
                productViewer: action.value,
            };

            break;
        default:
            nextState = state;
            break;
    }

    return nextState;
};

export default productReducer;
