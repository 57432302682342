import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import bootbox from "bootbox/bootbox.js";

// require jQuery normally
const $ = require("jquery");
global.$ = global.jQuery = $;

const Highcharts = require("highcharts");
global.Highcharts = Highcharts;

global.Routing = Routing;
global.bootbox = bootbox;

// Routing
import Routing from "../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

Routing.setRoutingData(require("../../../../js/fos_js_routes.json"));

// Translator
import Translator from "bazinga-translator";

Translator.fromJSON(require("../../../../js/translations/" + document.getElementsByTagName("html")[0].getAttribute("lang") + ".json"));

// App
var App = {
    initModalAvoirBill: function () {
        $(".modal-event-avoir-reprise-oeuvre").on("change", function () {
            var idFacture = $(this).data("id-facture");
            $(".modal-avoir-reprise-oeuvre-items-" + idFacture).toggle();
        });
    },
    /***********************
     *  INIT Payment
     **********************/
    initPayment: function () {
        $(".payment-checkbox").on("change", function () {
            $(".avoir-error").html("");

            var idBill = $(this).data("id-bill");
            var amount = $(this).data("amount");

            var listeCheckbox = $("input[type=checkbox]");

            var haveAvoirBill = false;

            listeCheckbox.each(function (index) {
                if ($(this).is(":checked") && $(this).hasClass("facture-avoir")) {
                    haveAvoirBill = true;
                }
            });

            if (haveAvoirBill) {
                /*  var listeFactureNormale = $('.facture-normal');
           var compteurChecked = 0;

           var factureNormalBillId = null;

           listeFactureNormale.each(function(index) {
           if($(this).is(':checked')) {
           compteurChecked++;
           factureNormalBillId = $(this).data('id-bill');
           }
           });

           if(compteurChecked > 1) {
           $('.montant-total-reglement-container').append("<div class='avoir-error'><p class='alert alert-danger'>Une facture d'avoir est applicable à une seule facture</p></div>");
           $('.payment-checkbox').attr('checked', false);
           $('.payment-input-item').val(0.00);
           $('.payment-input-item').attr('readonly', false);
           calculateSum();
           return;
           }

           if(compteurChecked == 1 && factureNormalBillId !== null) {
           $('.payment-input-item').attr('readonly', true);
           $('.payment-input-item-' + factureNormalBillId).attr('readonly', false);
           }
           */
                $('#add_payment_step2_paymentChoice option[value="OTHER"]').attr("selected", "selected");
                $('#add_payment_step2_paymentChoice option[value="CHECK"]').attr("selected", false);
            } else {
                $('#add_payment_step2_paymentChoice option[value="OTHER"]').attr("selected", false);
                $('#add_payment_step2_paymentChoice option[value="CHECK"]').attr("selected", "selected");
                //  $('.payment-input-item').attr('readonly', false);
            }

            if ($(this).is(":checked")) {
                $(".payment-input-item-" + idBill).val(amount);
            } else {
                $(".payment-input-item-" + idBill).val(0.0);
            }
            //  calculateSum();
        });
    },

    calculateSum: function () {
        var items = $(".event-calculate-amount");
        var somme = 0;

        items.each(function (key, item) {
            somme += parseFloat($(this).val());
        });

        if (somme < 0) {
            $(".payment-error").html("");
            $(".montant-total-reglement-container").append(
                "<div class='payment-error'><p class='alert alert-warning'>Votre client perdra " +
                    somme +
                    " € car il s'agit d'un avoir sous utilisé</p></div>"
            );
        } else {
            $(".payment-error").html("");
        }

        $(".montant-total-reglement").html(somme);
    },

    /***********************
     * Init element / Libs
     **********************/

    initToggleFormStock: function () {
        $(".toggleFormStock").dblclick(function () {
            var productStockId = $(this).data("id");
            $("#showStockProduct-" + productStockId).toggle();
            $("#formProductStock-" + productStockId).toggle();
        });
    },

    initPhotograph: function () {
        var selectedItems = new Array();

        $(".img-check").on("click", function () {
            $(this).toggleClass("check");

            if ($(this).hasClass("check")) {
                if (selectedItems.indexOf($(this).data("picture-id")) === -1) {
                    selectedItems.push($(this).data("picture-id"));
                }
            } else {
                if (selectedItems.indexOf($(this).data("picture-id")) !== -1) {
                    selectedItems.splice(selectedItems.indexOf($(this).data("picture-id")), 1);
                }
            }

            $(".listPictures").val("");

            selectedItems.forEach(function (e) {
                $(".listPictures").val($(".listPictures").val() + e + ";");
            });

            $(".listPictures").val(
                $(".listPictures")
                    .val()
                    .substr(0, $(".listPictures").val().length - 1)
            );
        });
    },

    initFormTableSubmit: function () {
        $(".form-table-submit").on("click", function (e) {
            e.preventDefault();
            var button = $(this);

            if (button.hasClass("form-table-group-toggle")) {
                $(".group-addCustomer-layout").toggle();
                return;
            }

            if ($("." + button.attr("data-check") + ":checked").length == 0) {
                App.modalAlert("Veuillez effectuer une sélection");
            } else {
                var formTable = $("#" + button.attr("data-form"));
                formTable.attr("action", button.attr("data-action"));

                if (button.hasClass("form-table-delete")) {
                    App.modalConfirmDelete(function () {
                        formTable.submit();
                    });
                } else {
                    formTable.submit();
                }
            }
        });
    },

    initChoiceMarket: function () {
        $(".choix-market").on("change", function () {
            window.location.href = Routing.generate("tco_market_set", { market: $(".selectpicker").val() });
        });
    },

    initActionMultiple: function () {
        $(".action-multiple").each(function () {
            $(this).on("click", function (event) {
                event.preventDefault();
                $(this).parents("form").attr("action", $(this).attr("href")).submit();
            });
        });
    },

    initCheckAll: function () {
        $(".check-all").change(function () {
            var isChecked = $(this).is(":checked");

            $("." + $(this).data("target")).each(function () {
                $(this).prop("checked", isChecked);
            });
        });
    },

    initAutocomplete: function () {
        $(".autocomplete").each(function () {
            var field = $(this);
            var _id = field.data("target");
            var list = $("#" + _id + "_list");
            var originValue = field.val();

            if (false == field.data("required")) {
                field.removeAttr("required");
            }

            list.on(
                {
                    "click.item-autocomplete": function (e) {
                        $(this)
                            .closest("li")
                            .fadeOut(300, function () {
                                $(this).remove();
                            });
                        e.preventDefault();
                        e.stopPropagation();
                    },
                },
                "a"
            );

            field.on("change", function (e) {
                if ("" == field.val()) {
                    $("#" + _id).val("");
                }

                if ($(".origin-hidden-type")) {
                    $(".origin-hidden-type").val(e.target.value);
                }
            });

            field.autocomplete({
                deferRequestBy: field.data("delay"),
                lookupLimit: field.data("max-items"),
                minChars: field.data("min-chars"),
                serviceUrl: field.data("url"),
                onSelect: function (suggestion) {
                    if (false == field.data("multiple")) {
                        $("#" + field.data("target")).val(suggestion.data);
                        originValue = suggestion.value;
                    } else {
                        var formName = field.closest("form").prop("name");

                        if (list.length) {
                            var li = list.find("#" + _id + "_" + suggestion.data);

                            if (!li.length) {
                                var name = (formName ? _id.replace(formName + "_", "") : _id).split("_");

                                if (formName) {
                                    name.unshift(formName);
                                }

                                name = (name.length > 1 ? name.shift() + "[" + name.join("][") + "]" : name.join()) + "[]";

                                li = $($("#" + _id).data("prototype"));
                                li.data("value", suggestion.data)
                                    .find("input:hidden")
                                    .val(suggestion.data)
                                    .attr("id", _id + "_" + suggestion.data)
                                    .attr("name", name)
                                    .end()
                                    .find(".autocomplete-item")
                                    .text(suggestion.value)
                                    .end()
                                    .appendTo(list);
                            }

                            field.val("");
                        }
                    }
                },
            });
        });
    },

    initMultiSelect: function () {
        $(".multiselect").each(function () {
            var enableFiltering = true,
                nonSelectedText = "Sélectionner",
                selectAllText = "Tout sélectionner",
                nSelectedText = " sélectionnés",
                maxHeight = 400;
            if ($(this).data("enable-filtering") || "undefined" != typeof $(this).data("enable-filtering")) {
                enableFiltering = $(this).data("enable-filtering");
            }

            if ($(this).data("non-select-text") || "undefined" != typeof $(this).data("non-select-text")) {
                nonSelectedText = $(this).data("non-select-text");
            }

            if ($(this).data("select-all-text") || "undefined" != typeof $(this).data("select-all-text")) {
                selectAllText = $(this).data("select-all-text");
            }

            if ($(this).data("n-selectd-text") || "undefined" != typeof $(this).data("n-selectd-text")) {
                selectAllText = $(this).data("n-selectd-text");
            }

            if ($(this).data("max-height") || "undefined" != typeof $(this).data("max-height")) {
                maxHeight = $(this).data("max-height");
            }

            $(this).multiselect({
                enableCaseInsensitiveFiltering: enableFiltering,
                enableFiltering: enableFiltering,
                filterPlaceholder: "Rechercher...",
                includeSelectAllOption: true,
                nonSelectedText: nonSelectedText,
                allSelectedText: "Tout sélectionné",
                selectAllText: selectAllText,
                nSelectedText: nSelectedText,
                numberDisplayed: 1,
                maxHeight: maxHeight,
                buttonWidth: "100%",
                templates: {
                    filter:
                        '<li class="multiselect-item filter"><div class="input-group"><input class="form-control multiselect-search" type="text"></div></li>',
                    filterClearBtn:
                        '<span class="input-group-btn"><button class="btn btn-default multiselect-clear-filter" type="button"><i class="fa fa-times-circle"></i></button></span>',
                },
            });
        });
    },

    initDatePicker: function () {
        $(".datepicker").datepicker({
            format: "dd/mm/yyyy",
            language: "fr",
        });

        $(".datepickerOnlyYear").datepicker({
            format: " yyyy", // Notice the Extra space at the beginning
            viewMode: "years",
            minViewMode: "years",
            orientation: "bottom", // <-- orientation en bas
        });
    },

    initSideBar: function () {
        $('.navbar-sidenav [data-toggle="tooltip"]').tooltip({
            template:
                '<div class="tooltip navbar-sidenav-tooltip" role="tooltip" style="pointer-events: none;"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
        });

        // Toggle the side navigation
        $("#sidenavToggler").click(function (e) {
            e.preventDefault();
            $("body").toggleClass("sidenav-toggled");
            $(".navbar-sidenav .nav-link-collapse").addClass("collapsed");
            $(".navbar-sidenav .sidenav-second-level, .navbar-sidenav .sidenav-third-level").removeClass("show");
        });

        // Force the toggled class to be removed when a collapsible nav link is clicked
        $(".navbar-sidenav .nav-link-collapse").click(function (e) {
            e.preventDefault();
            $("body").removeClass("sidenav-toggled");
        });
    },

    initCheckBox: function () {
        $(".check-all").change(function () {
            var checkAll = $(this);
            $("." + checkAll.attr("data-check")).prop("checked", checkAll.prop("checked"));
        });
    },

    initBootBoxModal: function () {
        $('[data-confirm="confirm-delete"]').each(function (index, item) {
            $(item).on("click", function () {
                if ($(this).data("action") === "submit") {
                    if ($(this).data("form")) {
                        App.modalConfirmDelete(() => {
                            $($(this).data("form")).submit();
                        });
                    } else {
                        console.warn("Précisez le selecteur du formulaire à submit.");
                    }
                } else {
                    App.modalConfirmDelete(function () {
                        App.redirect($(item).attr("data-href"));
                    });
                }
            });
        });

        $('[data-confirm="confirm-delete-submit"').each(function (index, item) {
            $(item).on("click", function () {
                App.modalConfirmDelete(function () {
                    App.redirect($(item).attr("data-href"));
                });
            });
        });

        $('[data-confirm="confirm-workflow"]').each(function (index, item) {
            $(item).on("click", function () {
                App.modalConfirmWorkflow(function () {
                    App.redirect($(item).attr("data-href"));
                });
            });
        });
    },

    initCustomerFilterFinancialHistoryModeAcquisition: function () {
        $(".event-select-customer-acquisition-mode").on("change", function () {
            $(".form-filter-customer-financialHistory").submit();
        });
    },

    /***********************
     * Custom Bootbox Modal functions
     **********************/

    redirect: function (url) {
        window.location.href = url;
    },

    modalConfirm: function (params) {
        var defaultParams = {
            title: "Confirmation",
            message: "Êtes-vous sûr ?",
            buttons: {
                cancel: {
                    label: "Non",
                },
                confirm: {
                    label: "Oui",
                },
            },
            callback: function (result) {},
        };
        if (undefined == params) {
            params = defaultParams;
        }
        bootbox.confirm(params);
    },

    modalAlert: function (message, title) {
        if (undefined == title) {
            title = "Attention";
        }

        bootbox.alert({
            message: message,
            title: title,
        });
    },

    modalConfirmDelete: function (callback) {
        var params = {
            title: "Supprimer",
            message: "Êtes-vous sûr de vouloir supprimer ?",
            buttons: {
                cancel: {
                    label: '<i class="fa fa-times"></i> Annuler',
                },
                confirm: {
                    label: '<i class="fa fa-check"></i> Oui, supprimer',
                },
            },
            callback: function (result) {
                if (result == true) {
                    callback();
                }
            },
        };
        this.modalConfirm(params);
    },

    modalConfirmWorkflow: function (callback) {
        var params = {
            title: "Continuer",
            message: "Êtes-vous sûr de vouloir passer à l'état suivant ?",
            buttons: {
                cancel: {
                    label: '<i class="fa fa-times"></i> Annuler',
                },
                confirm: {
                    label: '<i class="fa fa-check"></i> Oui, continuer',
                },
            },
            callback: function (result) {
                if (result == true) {
                    callback();
                }
            },
        };
        this.modalConfirm(params);
    },

    initSelectPicker: function () {
        $(".selectpicker").each(function (item) {
            if ($(this).find("option").first().val() === "") {
                $(this).find("option").first().text("Tous / Toutes");
            }
        });

        $(".selectpicker").selectpicker();

        $(".selectpicker").each(function (item) {
            var idCommun = $(this).attr("id") + "_dropdown";
            $(this).parent().attr("id", idCommun);

            var button = $(this).parent().find(".dropdown-toggle");
            button.data("boundary", idCommun);
        });
    },

    /**
    /**
     * INIT SELECT Agence
     */
    initSelectGallery: function () {
        $("#layout-select-gallery-event").on("change", function () {
            window.location.href = Routing.generate("admin_gallery_set", { gallery: $(this).val() });
        });
    },
    initCkeditor: function () {
        $(".editor").each(function () {
            ClassicEditor.create(document.getElementById($(this).attr("id")), {
                // plugins: [
                //   Alignment,
                //   EssentialsPlugin,
                //   BoldPlugin,
                //   ItalicPlugin,
                //   BlockQuotePlugin,
                //   LinkPlugin,
                //   ListPlugin
                // ],
                toolbar: [
                    "bold",
                    "italic",
                    // 'alignment',
                    "link",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                ],
            });
        });
    },
};

// Export APP functions
global.App = App;

$(document).ready(function () {
    App.initPhotograph();
    App.initActionMultiple();
    App.initAutocomplete();
    App.initCheckAll();
    App.initMultiSelect();
    App.initDatePicker();
    App.initCheckBox();
    App.initBootBoxModal();
    App.initSideBar();
    App.initFormTableSubmit();
    App.initPayment();
    App.initToggleFormStock();
    App.initSelectPicker();
    App.initCustomerFilterFinancialHistoryModeAcquisition();
    App.initModalAvoirBill();
    App.initSelectGallery();
    App.initCkeditor();
});

/***********************
 * ScrollTop
 **********************/

// Scroll to top button appear
$(document).scroll(function () {
    var scrollDistance = $(this).scrollTop();
    if (scrollDistance > 100) {
        $(".scroll-to-top").fadeIn();
    } else {
        $(".scroll-to-top").fadeOut();
    }
});
// Configure tooltips globally
$('[data-toggle="tooltip"]').tooltip();
// Smooth scrolling using jQuery easing
$(document).on("click", "a.scroll-to-top", function (event) {
    var $anchor = $(this);
    $("html, body")
        .stop()
        .animate(
            {
                scrollTop: $($anchor.attr("href")).offset().top,
            },
            1000,
            "easeInOutExpo"
        );
    event.preventDefault();
});
