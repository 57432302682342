import React from "react";
import Dropzone from "react-dropzone";
import Translator from "bazinga-translator";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

class ImageDropzone extends React.Component {
    state = {
        addRoute: this.props.addRoute,
        collection: this.props.collection,
        field: this.props.field,
        files: [],
        isLoading: false,
        redirectRoute: this.props.redirectRoute,
        title: this.props.title,
    };

    addPhoto = async () => {
        this.setState({
            isLoading: true,
        });

        const state = this.state;

        state.isLoading = false;

        try {
            const form = new FormData();
            const collection = state.collection;
            const field = state.field;

            state.files.map((file, index) => {
                form.set(collection + "[" + index + "]" + field, file);
            });

            const response = await fetch(state.addRoute, {
                body: form,
                credentials: "include",
                method: "POST",
            });

            if (response.status == 201) {
                window.location.href = state.redirectRoute;
            }
        } catch (error) {
            console.log(error);
        }

        this.setState(state);
    };

    onDrop = (acceptedFiles) => {
        const files = this.state.files;

        acceptedFiles.forEach((file) => {
            files.push(file);
        });

        this.setState({
            files: files,
        });
    };

    removeFile = (index) => {
        const files = [];

        this.state.files.forEach((file, idx) => {
            if (idx != index) {
                files.push(file);
            }
        });

        this.setState({
            files: files,
        });
    };

    renderDropzone(state, props) {
        const files = state.files;

        return (
            <div className="text-center">
                <Dropzone accept="image/*" onDrop={this.onDrop.bind(this)}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="dropzone">
                            <i className="fas fa-upload fa-2x"></i>
                        </div>
                    )}
                </Dropzone>

                {files.length > 0 && (
                    <div className="row mt-3">
                        {files.map((file, index) => {
                            return (
                                <div className="col-xl-4" key={index}>
                                    <img src={URL.createObjectURL(file)} style={{ maxHeight: 150 }} />
                                    <br />

                                    {file.name}
                                    <br />

                                    <a
                                        onClick={this.removeFile.bind(this, index)}
                                        style={{ cursor: "pointer" }}
                                        title={Translator.trans("back.app.action.remove")}
                                    >
                                        <i className="fas fa-trash"></i>
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                )}

                <div className="mt-3">
                    <a className="btn btn-primary mr-2" onClick={this.addPhoto.bind(this)} style={{ cursor: "pointer" }}>
                        {Translator.trans("arteo.form.submit")}
                    </a>
                    <a className="btn btn-default" href={props.redirectRoute}>
                        {Translator.trans("arteo.form.cancel")}
                    </a>
                </div>
            </div>
        );
    }

    render() {
        const state = this.state;
        const props = this.props;

        return (
            <div className="row">
                <div className="card">
                    <div className="card-header">{props.title}</div>
                    <div className="card-body">{this.renderDropzone(state, props)}</div>
                </div>
            </div>
        );
    }
}

export default ImageDropzone;
