import React from "react";
import { List, arrayMove } from "react-movable";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from "bazinga-translator";

export default class PressReviewList extends React.Component {
    state = {
        loading: false,
        idExpo: this.props.idExpo,
        arrPressReviews: [],
        notification: null,
    };

    loadPressReviews = async () => {
        try {
            this.setState({ loading: true });

            const response = await fetch(Routing.generate("arteo_admin_art_exposition_api_press_review", { id: this.state.idExpo }), {
                method: "GET",
                credentials: "same-origin",
            });

            if (response.status === 200) {
                const arrPressReviews = (await response.json()).map((pressReview) => {
                    return { ...pressReview, isChecked: false };
                });

                this.setState({ arrPressReviews: arrPressReviews, loading: false });
            }
        } catch (error) {
            console.log(error);

            this.setState({ loading: false });
        }
    };

    updateCheckboxState = (index, pressReview, isChecked = null) => {
        let arrPressReviews = [...this.state.arrPressReviews];

        if (isChecked !== null) {
            pressReview.isChecked = isChecked;
        } else {
            pressReview.isChecked = !pressReview.isChecked;
        }

        arrPressReviews.splice(index, 1, pressReview);

        this.setState({ ...this.state, arrPressReviews: arrPressReviews });

        const arrCheckedIdPressReviews = this.state.arrPressReviews
            .filter((pressReview) => {
                return pressReview.isChecked;
            })
            .map((pressReview) => pressReview.id);

        this.inputToSubmit.value = arrCheckedIdPressReviews.join(",");
    };

    loadDomElements = () => {
        this.inputToSubmit = document.querySelector("#checked-press-reviews");
    };

    toggleCheckAll = () => {
        let isChecked = document.querySelector("#check-all-press-reviews").checked;

        this.state.arrPressReviews.forEach((pressReview, index) => {
            this.updateCheckboxState(index, pressReview, isChecked);
        });
    };

    onDragPressReview = async (oldIndex, newIndex) => {
        let arrPressReviews = this.state.arrPressReviews;

        arrPressReviews = arrayMove(arrPressReviews, oldIndex, newIndex);

        this.setState({ arrPressReviews: arrPressReviews });

        let hasError = false;
        const form = new FormData();

        form.append("expo-press-reviews", JSON.stringify(arrPressReviews));

        const url = Routing.generate("arteo_admin_art_exposition_order_press_review", { id: this.state.idExpo });

        try {
            const response = await fetch(url, {
                method: "POST",
                body: form,
                credentials: "same-origin",
            });

            if (response.status !== 200) {
                hasError = true;
            }
        } catch (error) {
            hasError = true;
        }

        if (hasError) {
            arrPressReviews = arrayMove(arrPressReviews, newIndex, oldIndex);

            this.setState({
                arrPressReviews: arrPressReviews,
                notification: { type: "danger", message: "Une erreur est survenue" },
            });
        }
    };

    componentDidMount = async () => {
        this.loadPressReviews();
        this.loadDomElements();
    };

    render() {
        let pressReviewListDraggable = (
            <List
                values={this.state.arrPressReviews}
                onChange={({ oldIndex, newIndex }) => this.onDragPressReview(oldIndex, newIndex)}
                renderList={({ children, props, isDragged }) => (
                    <div className="table-responsive">
                        <table className="table table-striped table-hover datatable">
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            id="check-all-press-reviews"
                                            onChange={() => {
                                                this.toggleCheckAll();
                                            }}
                                        />
                                    </th>
                                    <th />
                                    <th>Titre</th>

                                    <th>Date de publication dans la presse</th>
                                    <th className="text-right">{Translator.trans("arteo.actions")}</th>
                                </tr>
                            </thead>
                            <tbody {...props}>{children}</tbody>
                        </table>
                    </div>
                )}
                renderItem={({ index, value, props, isDragged, isSelected }) => {
                    const urlShowPressReview = Routing.generate("arteo_admin_art_exposition_show_press", { id: value.id });
                    const urlEditPressReview = Routing.generate("arteo_admin_art_exposition_edit_press", { id: value.id });

                    const row = (
                        <tr
                            {...props}
                            style={{
                                cursor: isDragged ? "grabbing" : "grab",
                                backgroundColor: isDragged || isSelected ? "#EEE" : "#fafafa",
                                position: isDragged || isSelected ? "absolute" : "inherit",
                                width: isDragged || isSelected ? "100%" : "inherit",
                                display: isDragged || isSelected ? "flex" : "table-row",
                            }}
                        >
                            <td>
                                <input
                                    type="checkbox"
                                    id={"check-press-review-" + value.id}
                                    className="check-press-review"
                                    value={value.id}
                                    checked={value.isChecked}
                                    onChange={() => {
                                        this.updateCheckboxState(index, value);
                                    }}
                                />
                            </td>
                            <td>
                                <a href={urlShowPressReview} target="_blank">
                                    <img src={value.img} alt={value.title} />
                                </a>
                            </td>
                            <td>
                                <a href={urlShowPressReview} target="_blank">
                                    {value.title}
                                </a>
                            </td>
                            <td>{value.publicationDate !== undefined ? value.publicationDate : ""}</td>
                            <td className="text-right">
                                <a href={urlShowPressReview} className="mr-3">
                                    <i className="fa fa-eye"></i>
                                </a>
                                {value.canEdit ? (
                                    <a href={urlEditPressReview} className="mr-3" target="_blank">
                                        <span className="fas fa-edit"></span>
                                    </a>
                                ) : null}
                            </td>
                        </tr>
                    );

                    return isDragged ? (
                        <div className="table-responsive">
                            <table className="table table-hover table-striped" style={{ ...props.style, borderSpacing: 0 }}>
                                <tbody>{row}</tbody>
                            </table>
                        </div>
                    ) : (
                        row
                    );
                }}
            />
        );

        return <div>{pressReviewListDraggable}</div>;
    }
}
