import Translator from "bazinga-translator";
import React from "react";
import Iframe from "react-iframe";
import ReactPaginate from "react-paginate";
import { Spinner } from "reactstrap";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import FlashNotification from "./FlashNotification";

class NotificationList extends React.Component {
    state = {
        flashMessages: [],
        loadingSpinner: false,
        nbTotalNotificationsPage: 1,
        notifications: [],
        notificationsOffsetPage: 0,
        notificationsPerPage: 5,
        notificationTypes: [],
    };

    async loadNotifications() {
        this.setState({
            loadingSpinner: true,
        });

        const props = this.props;

        const params = {
            entityClassName: props.entityClassName,
            entityId: props.entityId,
            limit: this.state.notificationsPerPage,
            offset: this.state.notificationsOffsetPage,
            recipientClassName: props.recipientClassName,
        };

        const response = await fetch(Routing.generate("notification_api_get_all", params), {
            method: "GET",
            credentials: "same-origin",
        });

        if (response.status != 200) {
            const data = await response.json();
            const flashMessages = this.state.flashMessages;

            flashMessages.push({ type: "danger", message: data.error });

            this.setState({
                flashMessage: flashMessages,
                loadingSpinner: false,
            });
        } else {
            const data = await response.json();
            const state = this.state;

            state.nbPage = Math.ceil(data.nbNotifications / state.notificationsPerPage);
            state.loadingSpinner = false;

            if (!Array.isArray(data.notifications)) {
                state.notifications = Object.values(data.fiches);
            } else {
                state.notifications = data.notifications;
            }

            this.setState(state);
        }
    }

    handleClickPages(page) {
        this.setState({ notificationsOffsetPage: Math.ceil(page.selected * this.state.notificationsPerPage) }, () => this.loadNotifications());
    }

    handleSelectNbItem(event) {
        this.setState({ notificationsPerPage: event.target.value }, () => this.loadNotifications());
    }

    componentDidMount() {
        this.loadNotifications();
    }

    renderList(state) {
        return (
            <div className="table-responsive">
                <table className="table table-hover table-striped m-0 text-center">
                    <thead>
                        <tr>
                            <th>{Translator.trans("notification.table.header.date")}</th>
                            <th>{Translator.trans("notification.table.header.title")}</th>
                            <th>{Translator.trans("notification.table.header.sent")}</th>
                            <th>{Translator.trans("arteo.action.title")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.loadingSpinner ? (
                            <tr>
                                <td colSpan="4">
                                    <Spinner color="primary" />
                                </td>
                            </tr>
                        ) : (
                            state.notifications.map((notification) => {
                                const id = "notifications-" + notification.id + "-email";

                                return (
                                    <tr key={notification.id}>
                                        <td>{notification.dateNotify}</td>
                                        <td>{notification.title}</td>
                                        <td>{notification.isSent ? "Oui" : "Non"}</td>
                                        <td>
                                            <button className="btn btn-primary" data-toggle="modal" data-target={"#" + id}>
                                                {Translator.trans("notification.table.actions.open")}
                                            </button>

                                            <div aria-hidden="true" className="modal fade text-left" id={id} role="dialog" tabIndex="-1">
                                                <div className="modal-dialog modal-lg" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="mb-0">{Translator.trans("notification.table.header.message")}</h5>
                                                            <button aria-label="Close" className="close" data-dismiss="modal" type="button">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <strong>{Translator.trans("notification.message.subject")}</strong> :{" "}
                                                            {notification.subjectEmail}
                                                            <br />
                                                            <br />
                                                            <Iframe
                                                                url={notification.urlEmailIframe}
                                                                width="100%"
                                                                height="500px"
                                                                display="initial"
                                                                position="relative"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        const state = this.state;
        const span = <span className="page-link">...</span>;

        return (
            <div className="card card-border-color card-border-color-primary">
                <FlashNotification notifications={state.flashMessages} />

                <div className="card-body">
                    <div className="card-title">
                        <h5>{Translator.trans("notification.template.title")}</h5>
                    </div>
                    {this.renderList(state)}
                </div>

                <div className="row">
                    <div className="col-md-7 ml-2">
                        <ReactPaginate
                            activeClassName={"active"}
                            breakClassName="page-item"
                            breakLabel={span}
                            containerClassName={"pagination"}
                            marginPagesDisplayed={2}
                            nextClassName="page-item"
                            nextLabel={Translator.trans("app.pagination.next")}
                            nextLinkClassName="page-link"
                            onPageChange={this.handleClickPages.bind(this)}
                            pageClassName="page-item"
                            pageCount={this.state.nbTotalNotificationsPage}
                            pageRangeDisplayed={5}
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLabel={Translator.trans("app.pagination.previous")}
                            previousLinkClassName="page-link"
                        />
                    </div>
                    <div className="col-md-4">
                        <select className="form-control float-right mr-2" onChange={this.handleSelectNbItem.bind(this)}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="150">150</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotificationList;
