import React from "react";
import InputField from "./fields/InputField";
import TextareaField from "./fields/TextareaField";
import SelectField from "./fields/SelectField";
import AutocompleteField from "./fields/AutocompleteField";

const HtmlToReactParser = require("html-to-react").Parser;
const HtmlToReact = require("html-to-react");
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

export default class ParserHTML {
    parserHTML = new HtmlToReactParser();
    instructions = [
        {
            shouldProcessNode: function (node) {
                return node.name === "input" || node.name == "textarea" /*|| node.name == "select"*/;
            },
            processNode: function (node, children) {
                if (node.name === "select") {
                    return <SelectField {...node} />;
                }
                if (node.name === "textarea") {
                    return <TextareaField {...node} />;
                }

                if (node.attribs.class != undefined) {
                    if (node.attribs.class.includes("autocomplete")) {
                        return <AutocompleteField {...node.attribs} />;
                    }
                }

                return <InputField {...node.attribs} />;
            },
        },
        {
            shouldProcessNode: function (node) {
                return true;
            },
            processNode: processNodeDefinitions.processDefaultNode,
        },
    ];

    addInstruction(instruction) {
        this.instructions.push(instruction);
    }

    isValidNode() {
        return true;
    }

    parse(html) {
        return this.parserHTML.parseWithInstructions(html, this.isValidNode, this.instructions);
    }
}
