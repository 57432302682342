import React from "react";
import { List, arrayMove } from "react-movable";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from "bazinga-translator";

export default class PublicationList extends React.Component {
    state = {
        loading: false,
        idExpo: this.props.idExpo,
        arrPublications: [],
        notification: null,
    };

    loadPublications = async () => {
        try {
            this.setState({ loading: true });

            const response = await fetch(Routing.generate("arteo_admin_art_exposition_api_publication", { id: this.state.idExpo }), {
                method: "GET",
                credentials: "same-origin",
            });

            if (response.status === 200) {
                const arrPublications = (await response.json()).map((publication) => {
                    return { ...publication, isChecked: false };
                });

                this.setState({ arrPublications: arrPublications, loading: false });
            }
        } catch (error) {
            console.log(error);

            this.setState({ loading: false });
        }
    };

    updateCheckboxState = (index, publication, isChecked = null) => {
        let arrPublications = [...this.state.arrPublications];

        if (isChecked !== null) {
            publication.isChecked = isChecked;
        } else {
            publication.isChecked = !publication.isChecked;
        }

        arrPublications.splice(index, 1, publication);

        this.setState({ ...this.state, arrPublications: arrPublications });

        const arrCheckedIdPublications = this.state.arrPublications
            .filter((publication) => {
                return publication.isChecked;
            })
            .map((publication) => publication.id);

        this.inputToSubmit.value = arrCheckedIdPublications.join(",");
    };

    loadDomElements = () => {
        this.inputToSubmit = document.querySelector("#checked-publications");
    };

    toggleCheckAll = () => {
        let isChecked = document.querySelector("#check-all-publications").checked;

        this.state.arrPublications.forEach((publication, index) => {
            this.updateCheckboxState(index, publication, isChecked);
        });
    };

    onDragPublication = async (oldIndex, newIndex) => {
        let arrPublications = this.state.arrPublications;

        arrPublications = arrayMove(arrPublications, oldIndex, newIndex);

        this.setState({ arrPublications: arrPublications });

        let hasError = false;
        const form = new FormData();

        form.append("expo-publications", JSON.stringify(arrPublications));

        const url = Routing.generate("arteo_admin_art_exposition_order_publication", { id: this.state.idExpo });

        try {
            const response = await fetch(url, {
                method: "POST",
                body: form,
                credentials: "same-origin",
            });

            if (response.status !== 200) {
                hasError = true;
            }
        } catch (error) {
            hasError = true;
        }

        if (hasError) {
            arrPublications = arrayMove(arrPublications, newIndex, oldIndex);

            this.setState({
                arrPublications: arrPublications,
                notification: { type: "danger", message: "Une erreur est survenue" },
            });
        }
    };

    componentDidMount = async () => {
        this.loadPublications();
        this.loadDomElements();
    };

    render() {
        let publicationListDraggable = (
            <List
                values={this.state.arrPublications}
                onChange={({ oldIndex, newIndex }) => this.onDragPublication(oldIndex, newIndex)}
                renderList={({ children, props, isDragged }) => (
                    <div className="table-responsive">
                        <table className="table table-striped table-hover datatable">
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            id="check-all-publications"
                                            onChange={() => {
                                                this.toggleCheckAll();
                                            }}
                                        />
                                    </th>
                                    <th />
                                    <th>Titre</th>
                                    <th>Auteur</th>
                                    <th>Année de publication</th>
                                    <th className="text-right">{Translator.trans("arteo.actions")}</th>
                                </tr>
                            </thead>
                            <tbody {...props}>{children}</tbody>
                        </table>
                    </div>
                )}
                renderItem={({ index, value, props, isDragged, isSelected }) => {
                    const urlShowPublication = Routing.generate("arteo_admin_art_exposition_show_publication", { id: value.id });
                    const urlEditPublication = Routing.generate("arteo_admin_art_exposition_edit_publication", { id: value.id });

                    const row = (
                        <tr
                            {...props}
                            style={{
                                cursor: isDragged ? "grabbing" : "grab",
                                backgroundColor: isDragged || isSelected ? "#EEE" : "#fafafa",
                                position: isDragged || isSelected ? "absolute" : "inherit",
                                width: isDragged || isSelected ? "100%" : "inherit",
                                display: isDragged || isSelected ? "flex" : "table-row",
                            }}
                        >
                            <td>
                                <input
                                    type="checkbox"
                                    id={"check-publication-" + value.id}
                                    className="check-publication"
                                    value={value.id}
                                    checked={value.isChecked}
                                    onChange={() => {
                                        this.updateCheckboxState(index, value);
                                    }}
                                />
                            </td>
                            <td>
                                <a href={urlShowPublication} target="_blank">
                                    <img src={value.img} alt={value.title} />
                                </a>
                            </td>
                            <td>
                                <a href={urlShowPublication} target="_blank">
                                    {value.title}
                                </a>
                            </td>
                            <td>{value.author}</td>
                            <td>{value.publicationYear}</td>
                            <td className="text-right">
                                <a href={urlShowPublication} className="mr-3">
                                    <i className="fa fa-eye"></i>
                                </a>
                                {value.canEdit ? (
                                    <a href={urlEditPublication} className="mr-3" target="_blank">
                                        <span className="fas fa-edit"></span>
                                    </a>
                                ) : null}
                            </td>
                        </tr>
                    );

                    return isDragged ? (
                        <div className="table-responsive">
                            <table className="table table-hover table-striped" style={{ ...props.style, borderSpacing: 0 }}>
                                <tbody>{row}</tbody>
                            </table>
                        </div>
                    ) : (
                        row
                    );
                }}
            />
        );

        return <div>{publicationListDraggable}</div>;
    }
}
