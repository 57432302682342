import Translator from "bazinga-translator";
import React from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import ProductMiniature from "./ProductMiniature";

/**
 * TEMPORAIRE
 */
function initCart() {
    $(".add-cart-product").on("click", function (e) {
        e.preventDefault();

        var productId = $(this).data("product");
        var customerId = $(this).data("customer");
        var url = Routing.generate("arteo_admin_business_cart_add_product");

        $.ajax({
            url: url,
            data: { productId, customerId },
            success: function (view) {
                $("#modalCart").replaceWith(view);
                initCartDelete();
                App.initAutocomplete();
                $("#modalCart").modal("show");
                initAssignCustomer();
            },
        });
    });
    initCartDelete();
}

function initAssignCustomer() {
    $("#assign-customer-form").submit(function (e) {
        e.preventDefault();
        var form = $(this);
        var url = Routing.generate("arteo_admin_business_cart_add_product");
        $("#modalCart").modal("hide");

        $.ajax({
            type: "POST",
            url: url,
            data: form.serialize(), // serializes the form's elements.
            success: function (view) {
                $("#modalCart").replaceWith(view);
                initCartDelete();
                $("#modalCart").modal("show");
            },
        });
    });
}

function initCartDelete() {
    $(".delete-cart-product").each(function () {
        $(this).on("click", function (e) {
            e.preventDefault();

            var productId = $(this).data("product");
            var customerId = $(this).data("customer");
            var row = $(this).closest("tr");
            var url = Routing.generate("arteo_admin_business_cart_delete_product");

            $.ajax({
                url: url,
                data: { productId, customerId },
                success: function (value) {
                    row.remove();
                    $("#actualCost").text(value.toLocaleString("fr-FR", { style: "currency", currency: "EUR" }));
                },
            });
        });
    });
}

/**
 * FIN TEMPORAIRE
 */

class SalesProduct extends React.Component {
    state = {
        customerCartId: null,
        isLaoding: false,
        products: [],
    };

    getProducts = async () => {
        this.setState({ isLaoding: true });

        try {
            const urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get("page");
            const limitPage = urlParams.get("limitPage");
            const params = {};

            if (page != null) {
                params.page = page;
            }

            if (limitPage != null) {
                params.limitPage = limitPage;
            }

            const response = await fetch(Routing.generate("arteo_admin_art_sales_product_get", params), {
                method: "GET",
                credentials: "same-origin",
            });

            if (response.status == 200) {
                const data = await response.json();

                this.setState(
                    {
                        customerCartId: data.customerCart,
                        isLaoding: false,
                        products: data.products,
                    },
                    () => initCart()
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    addProductToProductViewer = (product) => {
        this.props.dispatch({ type: "setProductToAdd", value: product });
    };

    componentDidMount() {
        this.getProducts();
    }

    renderProducts() {
        const state = this.state;

        if (state.isLaoding) {
            return (
                <div className="text-center mb-4">
                    <Spinner className="primary" />
                </div>
            );
        }

        const products = state.products;
        const customerCartId = state.customerCartId;
        const props = this.props;
        const productViewer = props.productViewer;

        return (
            <div className="row text-center">
                {products.map((product) => {
                    return (
                        <div className="col-lg-3 col-md-4 col-xs-6 mb-4" key={product.id}>
                            <ProductMiniature product={product} />

                            <div className="btn-group btn-space">
                                <a
                                    href={Routing.generate("arteo_admin_art_sales_product_pdf", { id: product.id })}
                                    className="btn btn-default"
                                    data-container="body"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    target="_blank"
                                    title={Translator.trans("arteo.admin_art.product.search.table.downloadPdf")}
                                >
                                    <span className="icon fas fa-download"></span>
                                </a>

                                {customerCartId != null && (
                                    <>
                                        <a
                                            className="add-cart-product btn btn-default"
                                            data-customer={customerCartId}
                                            data-container="body"
                                            data-product={product.id}
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            href={Routing.generate("arteo_admin_art_sales_product_show", { id: product.id })}
                                            title={Translator.trans("arteo.admin_art.product.search.table.addToCart")}
                                        >
                                            <i className="fas fa-cart-plus"></i>
                                        </a>
                                        <div id="modalCart"></div>
                                    </>
                                )}

                                {productViewer != null && (
                                    <button
                                        className="btn btn-default"
                                        disabled={
                                            !(!productViewer.items.find((i) => i.product.id == product.id) && props.productToAdd == null) ||
                                            productViewer.productViewerCustomers.length > 0
                                        }
                                        data-container="body"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        onClick={this.addProductToProductViewer.bind(this, product)}
                                        title={Translator.trans("arteo.admin_art.product_viewer.action.add_item")}
                                    >
                                        <i className="fas fa-plus"></i>
                                    </button>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        return this.renderProducts();
    }
}

export default connect((state) => state.productReducer)(SalesProduct);
