import React from "react";
import { List, arrayMove } from "react-movable";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from "bazinga-translator";
import ReactPaginate from "react-paginate";

export default class ProductList extends React.Component {
    state = {
        expoId: this.props.expoId,
        loading: false,
        notification: null,
        number: "",
        limit: 10,
        offset: 0,
        products: [],
        productId: null,
        title: "",
    };

    loadProducts = async () => {
        this.setState({ loading: true });

        const state = this.state;

        try {
            const response = await fetch(
                Routing.generate("arteo_admin_art_exposition_api_products", {
                    id: state.expoId,
                    number: state.number,
                    limit: state.limit,
                    offset: state.offset,
                    title: state.title,
                }),
                {
                    credentials: "same-origin",
                    method: "GET",
                }
            );

            if (response.status === 200) {
                const data = await response.json();

                state.products = data.products.map((product) => {
                    return { ...product, isChecked: false };
                });

                state.pagesTotal = Math.ceil(data.total / state.limit);
            }
        } catch (error) {
            console.log(error);
        }

        state.loading = false;

        this.setState(state);
    };

    loadDomElements = () => {
        this.inputToSubmit = document.querySelector("#checked-featured-products");
    };

    updateCheckboxState = (index, product, isChecked = null) => {
        let products = [...this.state.products];

        if (isChecked !== null) {
            product.isChecked = isChecked;
        } else {
            product.isChecked = !product.isChecked;
        }

        products.splice(index, 1, product);

        this.setState({ ...this.state, products: products });

        const arrCheckedIdProducts = this.state.products
            .filter((product) => {
                return product.isChecked;
            })
            .map((product) => product.id);

        this.inputToSubmit.value = arrCheckedIdProducts.join(",");
    };

    toggleCheckAll = () => {
        const isChecked = document.querySelector("#check-all-featured-products").checked;

        this.state.products.forEach((product, index) => {
            this.updateCheckboxState(index, product, isChecked);
        });
    };

    onDragProduct = async (oldIndex, newIndex) => {
        let products = this.state.products;

        products = arrayMove(products, oldIndex, newIndex);

        this.setState({
            products: products,
        });

        let e = false;
        let form = new FormData();

        form.append("products", JSON.stringify(products));

        let url = Routing.generate("arteo_admin_art_exposition_ordered_items", { id: this.state.expoId });

        try {
            let response = await fetch(url, {
                method: "POST",
                body: form,
                credentials: "same-origin",
            });

            if (response.status !== 200) {
                e = true;
            }
        } catch (error) {
            e = true;
        }

        if (e) {
            products = arrayMove(products, newIndex, oldIndex);

            this.setState({
                products: products,
                notification: { type: "danger", message: "Une erreur est survenue" },
            });
        }
    };

    async componentDidMount() {
        this.loadProducts();
        this.loadDomElements();
    }

    handleLimit(event) {
        this.setState({ limit: event.target.value }, () => this.loadProducts());
    }

    handleOffset(page) {
        this.setState({ offset: Math.ceil(page.selected * this.state.limit) }, () => this.loadProducts());
    }

    handleNumber(event) {
        this.setState({ number: event.target.value }, () => this.loadProducts());
    }

    handleTitle(event) {
        this.setState({ title: event.target.value }, () => this.loadProducts());
    }

    renderList(state) {
        return (
            <List
                values={state.products}
                onChange={({ oldIndex, newIndex }) => this.onDragProduct(oldIndex, newIndex)}
                renderList={({ children, props, isDragged }) => (
                    <div className="table-responsive">
                        <table className="table table-striped table-hover datatable">
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            id="check-all-featured-products"
                                            onChange={() => {
                                                this.toggleCheckAll();
                                            }}
                                        />
                                    </th>
                                    <th></th>
                                    <th>{Translator.trans("arteo.admin_art.product.search.table.title")}</th>
                                    <th>{Translator.trans("arteo.admin_art.product.search.table.artist")}</th>
                                    <th>{Translator.trans("arteo.admin_art.product.search.table.category")}</th>
                                    <th>{Translator.trans("arteo.admin_art.product.search.table.code")}</th>
                                    <th>{Translator.trans("arteo.admin_art.product.search.table.wantedPrice")}</th>
                                    <th colSpan="2"></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody {...props}>{children}</tbody>
                        </table>
                    </div>
                )}
                renderItem={({ index, value, props, isDragged, isSelected }) => {
                    const urlShowProduct = Routing.generate("arteo_admin_art_product_show", { id: value.id });
                    const urlExportPdfProduct = Routing.generate("arteo_admin_art_product_pdf", { id: value.id });
                    const urlAuthCertificate = Routing.generate("arteo_admin_art_product_authenticity_certificate", { id: value.id });

                    const row = (
                        <tr
                            {...props}
                            style={{
                                cursor: isDragged ? "grabbing" : "grab",
                                backgroundColor: isDragged || isSelected ? "#EEE" : "#fafafa",
                                position: isDragged || isSelected ? "absolute" : "inherit",
                                width: isDragged || isSelected ? "100%" : "inherit",
                                display: isDragged || isSelected ? "flex" : "table-row",
                            }}
                        >
                            <td>
                                <input
                                    type="checkbox"
                                    id={"check-featured-product-" + value.id}
                                    value={value.id}
                                    checked={value.isChecked}
                                    onChange={() => {
                                        this.updateCheckboxState(index, value);
                                    }}
                                />
                            </td>
                            <td className="user-avatar cell-detail user-info">
                                <a href={value.img_original} data-lightbox={"exposition-" + value.id}>
                                    <img src={value.img} />
                                </a>
                            </td>
                            <td style={{ width: "200px" }}>
                                <a href={urlShowProduct} target="_blank">
                                    {value.title}
                                </a>
                            </td>
                            <td>{value.artist}</td>
                            <td>{value.category}</td>
                            <td>{value.code}</td>
                            <td>
                                {value.state.name === "Vendu" ? (
                                    <span className="blur-text">{value.wanted_price}€</span>
                                ) : (
                                    <span>{value.wanted_price} €</span>
                                )}
                            </td>
                            <td className="text-center text-nowrap">
                                <span className="label" style={{ backgroundColor: value.state.color }}>
                                    {value.state.name}
                                </span>
                                <br />
                                {value.acquisitionMode ? <span className="label label-black">{value.acquisitionMode}</span> : null}
                            </td>
                            <td>
                                <span className={"label label-" + value.onlineClass}>{value.onlineLabel}</span>
                                <br />
                                {value.statusOnWebsiteLabel !== undefined ? (
                                    <span className={"label label-" + value.statusOnWebsiteClass}>{value.statusOnWebsiteLabel}</span>
                                ) : (
                                    ""
                                )}
                            </td>
                            <td className="text-center">
                                <div className="btn-group btn-space">
                                    <a
                                        href={urlExportPdfProduct}
                                        target="_blank"
                                        className="btn btn-default"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        data-container="body"
                                        title={Translator.trans("arteo.admin_art.product.search.table.downloadPdf")}
                                    >
                                        <span className="icon fas fa-download"></span>
                                    </a>
                                    {value.authenticityCertificate !== "" && value.authenticityCertificate !== undefined ? (
                                        <a
                                            href={urlAuthCertificate}
                                            target="_blank"
                                            className="btn btn-default"
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            data-container="body"
                                            title={Translator.trans("arteo.admin_art.product.search.table.downloadCertificate")}
                                        >
                                            <span className="icon fas fa-file-alt"></span>
                                        </a>
                                    ) : null}
                                </div>
                                <br />
                            </td>
                        </tr>
                    );

                    return isDragged ? (
                        <div className="table-responsive">
                            <table className="table table-hover table-striped" style={{ ...props.style, borderSpacing: 0 }}>
                                <tbody>{row}</tbody>
                            </table>
                        </div>
                    ) : (
                        row
                    );
                }}
            />
        );
    }

    renderSearch(state) {
        return (
            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group row">
                                <label className="col-form-label col-sm-4">{Translator.trans("arteo.admin_art.product.edit.form.title")}</label>
                                <div className="col-sm-8">
                                    <input className="form-control" onChange={this.handleTitle.bind(this)} type="text" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group row">
                                <label className="col-form-label col-sm-4">
                                    {Translator.trans("arteo.admin_art.product.search.form.bookPoliceNumber")}
                                </label>
                                <div className="col-sm-8">
                                    <input className="form-control" onChange={this.handleNumber.bind(this)} type="text" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const state = this.state;
        const span = <span className="page-link">...</span>;

        return (
            <div>
                {this.renderSearch(state)}
                {this.renderList(state)}

                <div className="row mt-4">
                    <div className="col-md-8">
                        <ReactPaginate
                            activeClassName={"active"}
                            breakClassName="page-item"
                            breakLabel={span}
                            containerClassName={"pagination"}
                            marginPagesDisplayed={2}
                            nextClassName="page-item"
                            nextLabel={Translator.trans("app.pagination.next")}
                            nextLinkClassName="page-link"
                            onPageChange={this.handleOffset.bind(this)}
                            pageClassName="page-item"
                            pageCount={state.pagesTotal}
                            pageRangeDisplayed={5}
                            previousClassName="page-item"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            previousLabel={Translator.trans("app.pagination.previous")}
                        />
                    </div>
                    <div className="col-md-4">
                        <select className="selectpicker form-control float-right" onChange={this.handleLimit.bind(this)}>
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="150">150</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}
