import Translator from "bazinga-translator";
import React from "react";
import { connect } from "react-redux";
import ProductMiniature from "../artwork/ProductMiniature";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ParserHTML from "../parser/ParserHTML";

const parserHTML = new ParserHTML();

class ProductViewerCreator extends React.Component {
    state = {
        flashMessages: [],
        isLoading: false,
        modalForm: null,
        modalIsOpen: false,
        productToAdd: null,
        productViewer: null,
    };

    setProductViewer = (productViewer) => {
        this.setState(
            {
                isLoading: false,
                productViewer: productViewer,
            },
            () => this.props.dispatch({ type: "setProductViewer", value: productViewer })
        );
    };

    getProductViewer = async () => {
        try {
            const response = await fetch(Routing.generate("arteo_admin_art_product_viewer_get_of_user"), {
                method: "GET",
                credentials: "same-origin",
            });

            if (response.status == 200) {
                const productViewer = await response.json();

                this.setProductViewer(productViewer);
            }
        } catch (error) {
            console.log(error);
        }
    };

    addProduct = async (product) => {
        try {
            const response = await fetch(
                Routing.generate("arteo_admin_art_product_viewer_item_add", { id: this.state.productViewer.id, product: product.id }),
                {
                    method: "GET",
                    credentials: "same-origin",
                }
            );

            if (response.status == 201) {
                const productViewer = await response.json();

                this.setProductViewer(productViewer);
            }

            this.props.dispatch({ type: "setProductToAdd", value: null });
        } catch (error) {
            console.log(error);
        }
    };

    removeProduct = async (item) => {
        this.setState({ isLoading: true });

        try {
            const response = await fetch(Routing.generate("arteo_admin_art_product_viewer_item_remove", { id: item.id }), {
                method: "GET",
                credentials: "same-origin",
            });

            if (response.status == 201) {
                const productViewer = await response.json();

                this.setProductViewer(productViewer);
            }
        } catch (error) {
            console.log(error);
        }
    };

    handleModal = (open, form = null) => {
        if (form != null) {
            form = parserHTML.parse(form);
        }

        this.setState({
            modalForm: form,
            modalIsOpen: open,
        });
    };

    getModalForm = async () => {
        try {
            const response = await fetch(Routing.generate("arteo_admin_art_product_viewer_customer_send", { id: this.state.productViewer.id }), {
                method: "GET",
                credentials: "same-origin",
            });

            if (response.status == 200) {
                const form = await response.text();

                this.handleModal(true, form);
            }
        } catch (error) {
            console.log(error);
        }
    };

    submitModalForm = async () => {
        try {
            $("#product_viewer_customer_content").val($(".ck-content").html());

            const response = await fetch(Routing.generate("arteo_admin_art_product_viewer_customer_send", { id: this.state.productViewer.id }), {
                body: new FormData(document.getElementById("product-viewer-customer-form")),
                credentials: "same-origin",
                method: "POST",
            });

            if (response.status == 201) {
                const productViewer = await response.json();

                this.setProductViewer(productViewer);
                this.handleModal(false);

                const flashMessages = this.state.flashMessages;

                flashMessages.push({ type: "success", message: Translator.trans("arteo.admin_art.product_viewer_customer.flash.send.success") });

                this.setState({ flashMessages: flashMessages });
            } else {
                const form = await response.text();

                this.handleModal(true, form);
            }
        } catch (error) {
            console.log(error);
        }
    };

    initInput = () => {
        App.initCkeditor();
    };

    removeFlashMessage = (flashMessage) => {
        const flashMessages = [];

        this.state.flashMessages.forEach((fm) => {
            if (fm != flashMessage) {
                flashMessages.push(fm);
            }
        });

        this.setState({
            flashMessages: flashMessages,
        });
    };

    componentDidMount() {
        this.getProductViewer();
    }

    componentDidUpdate() {
        const productToAdd = this.props.productToAdd;

        if (productToAdd != this.state.productToAdd) {
            this.setState({ productToAdd: productToAdd }, () => {
                if (productToAdd != null) {
                    this.addProduct(productToAdd);
                }
            });
        }
    }

    renderItems(productViewer) {
        return (
            <div className="row text-center">
                {productViewer.items.map((item) => {
                    const product = item.product;
                    return (
                        <div className="col-lg-6 col-md-6 col-xs-6 mb-4" key={item.id}>
                            <ProductMiniature product={product} />
                            <div className="btn-group btn-space">
                                <button
                                    className="btn btn-default"
                                    disabled={this.state.isLoading || productViewer.productViewerCustomers.length > 0}
                                    data-container="body"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    onClick={this.removeProduct.bind(this, item)}
                                    title={Translator.trans("arteo.admin_art.product_viewer.action.remove_item")}
                                >
                                    <i className="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    renderModal(state) {
        return (
            <Modal isOpen={state.modalIsOpen} size="xl" onOpened={this.initInput.bind(this)} toggle={this.handleModal.bind(this, false)}>
                <ModalHeader toggle={this.handleModal.bind(this, false)}>
                    {Translator.trans("arteo.admin_art.product_viewer.action.send")}
                </ModalHeader>
                <ModalBody>{state.modalForm}</ModalBody>
                <ModalFooter>
                    <button className="btn btn-default" onClick={this.submitModalForm.bind(this)}>
                        {Translator.trans("app.form.submit")}
                    </button>
                    <button className="btn btn-secondary" onClick={this.handleModal.bind(this, false)}>
                        {Translator.trans("app.form.cancel")}
                    </button>
                </ModalFooter>
            </Modal>
        );
    }

    renderFlashMessages(state) {
        const flashMessages = state.flashMessages;

        return flashMessages.map((flashMessage, index) => {
            return (
                <div className={"alert alert-" + flashMessage.type} key={index} role="alert">
                    {flashMessage.message}

                    <button aria-label="Close" className="close" onClick={this.removeFlashMessage.bind(this, flashMessage)} type="button">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            );
        });
    }

    render() {
        const state = this.state;
        const productViewer = state.productViewer;

        if (productViewer == null) {
            return "";
        }
        return (
            <>
                {this.renderFlashMessages(state)}

                <div className="card">
                    <div className="card-header">
                        Sélection d'oeuvres
                        <div className="btn-group btn-space float-right">
                            <a
                                className="btn btn-default"
                                href={Routing.generate("arteo_admin_art_product_viewer_show", { token: productViewer.token })}
                                target="_blank"
                                title={Translator.trans("arteo.admin_art.product_viewer.action.show")}
                            >
                                <i className="fas fa-eye"></i>
                            </a>
                            <button
                                className="btn btn-default"
                                onClick={this.getModalForm.bind(this)}
                                title={Translator.trans("arteo.admin_art.product_viewer.action.send")}
                            >
                                <i className="fas fa-paper-plane"></i>
                            </button>

                            {productViewer.productViewerCustomers.length > 0 && (
                                <a
                                    className="btn btn-default"
                                    href={Routing.generate("arteo_admin_art_product_viewer_finish", { id: productViewer.id })}
                                    title={Translator.trans("arteo.admin_art.product_viewer.action.finish")}
                                >
                                    <i className="fas fa-check"></i>
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="card-body">{this.renderItems(productViewer)}</div>
                </div>

                {this.renderModal(state)}
            </>
        );
    }
}

export default connect((state) => state.productReducer)(ProductViewerCreator);
