import React from "react";
import ReactPaginate from "react-paginate";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from "bazinga-translator";
import { Spinner } from 'reactstrap';

const numberFormatter = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" });

export default class PaymentList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingSpinner: true,
            payments: [],
            paymentsPerPage: 50,
            paymentsOffsetPage:0,
            nbTotalPaymentsPage: 1,
            isGranted: this.props.isGranted,
            flashMessages: [],
            currentPage: 0,
            listCheckedItems: [],
            listCheckedAll: [],
            checkAll: false,
            orderBy: null,
            directionOrderBy: null,
        };
    }

    componentDidMount() {
        this.loadPayments();
        this.initSearchSaver();

    }

    componentDidUpdate() {
        this.state.listCheckedItems.forEach(item => {
            let checkbox = document.getElementById(`check-payment-${item}`);
            if(checkbox !== null) {
                checkbox.checked = true;
            }
        });

        this.state.listCheckedAll.forEach(item => {
            let checkbox = document.getElementById(`check-all-payment-${item}`)
            if( checkbox !== null) {
                checkbox.checked = true;
            }
        })
    }

    initSearchSaver = async() => {
        $("#payment-form-search").on('submit', (e) => {
            this.loadPayments();
        })

    };

    loadPayments = async() => {
        this.setState({
            loadingSpinner: true
        });

        let params = {
            limit: this.state.paymentsPerPage,
            offset: this.state.paymentsOffsetPage,
            orderBy: this.state.orderBy,
            direction: this.state.directionOrderBy
        };

        let url = Routing.generate('payments_api_get_all', params);


        let response = await fetch(url, {
            method : 'GET',
            credentials: "same-origin",
        });

        if (response.status !== 200) {
            let responseData = await response.json();

            let flashMessages = this.state.flashMessages;

            flashMessages.push({
                type: 'danger',
                message: responseData.error
            });

            this.setState({
                flashMessages: flashMessages,
                loadingSpinner: false
            });
        } else {
            let responseData = await response.json();

            let nbPage = Math.ceil(responseData.nbPayments / this.state.paymentsPerPage);

            this.setState({
                payments: JSON.parse(responseData.payments),
                nbTotalPaymentsPage: nbPage,
                loadingSpinner: false
            })
        }
    };

    handleClickPage(page) {
        let offset = Math.ceil(page.selected * this.state.paymentsPerPage);

        this.setState({paymentsOffsetPage: offset, currentPage: page.selected, checkAll: false}, () => {
            this.loadPayments();
        });
    };

    handleSelectNbItem(event) {
        this.setState({paymentsPerPage : event.target.value}, () => {
            this.loadPayments();
        })
    };

    checkItem(paymentId) {
        let listCheckedItems = this.state.listCheckedItems;

        if (listCheckedItems.includes(paymentId)) {
            listCheckedItems.splice( listCheckedItems.findIndex(val => val === paymentId), 1)
        } else {
            listCheckedItems.push(paymentId);
        }

        this.setState({listCheckedItems: listCheckedItems});

    };

    checkAll(currentPage) {
        const listCheckedItems = this.state.listCheckedItems;
        const listCheckedAll = this.state.listCheckedAll;

        if(listCheckedAll.findIndex(val => val === currentPage) === -1) {
            listCheckedAll.push(currentPage);
            this.state.payments.forEach(payment => {
                if(listCheckedItems.findIndex(val => val === payment.id) === -1) {
                    listCheckedItems.push(payment.id)
                }
            });
        } else {
            listCheckedAll.splice(listCheckedAll.findIndex(val => val === currentPage));
            this.state.payments.forEach((payment) => {
                let checkbox = document.getElementById(`check-payment-${payment.id}`);

                if(checkbox !== null) {
                    checkbox.checked = false;
                }

                if(listCheckedItems.findIndex(val => val === payment.id) !== -1) {
                    listCheckedItems.splice(listCheckedItems.findIndex(val => val === payment.id), 1)
                }
            });
        }

        this.setState({listCheckedItems: listCheckedItems, listCheckedAll: listCheckedAll});

    }

    orderDateAsc() {
        this.setState({orderBy: "datePayment"});
        this.setState({directionOrderBy: "asc"});

        this.loadPayments();
    }

    orderDateDesc() {
        this.setState({orderBy: "datePayment"});
        this.setState({directionOrderBy: "desc"});

        this.loadPayments();
    }

    render() {

        const span = <span className="page-link">...</span>;

        const dateOptions = {year: 'numeric', month: 'numeric', day: 'numeric'};

        let totalAmount = 0;

        let urlIndex = Routing.generate('arteo_admin_payment_index');

        let dropdownAction = () => {
            let checkedData = '';
            checkedData = checkedData.concat(this.state.listCheckedItems.map(item => item).join(';'));
            let urlDeletePayment = Routing.generate('arteo_admin_payment_delete') + '?checkedData=' + checkedData ;

            let urlBankPayment = Routing.generate('arteo_admin_banked_new') + '?checkedData=' + checkedData;

            return(

                <div className="dropdown">
                    <button className="btn btn-default" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {Translator.trans('arteo.list.actions-list')}
                        <span className="caret"> </span>
                    </button>
                    <div className="dropdown-menu">
                        <a className="dropdown-item btn btn-link" href={urlDeletePayment}><i className="fas fa-times"></i> {Translator.trans('arteo.admin_payment.search.actions.delete')}</a>
                        <a className="dropdown-item btn btn-link" href={urlBankPayment}><i className="fas fa-money-bill-wave"></i> {Translator.trans('arteo.admin_payment.search.actions.banked')}</a>
                    </div>
                </div>
            )
        };


        let listingPayments = this.state.payments.map((payment, key) => {

            totalAmount += parseInt(payment.amount);

            let listingPaymentItem = payment.paymentItems.map((item, j) => {

                const urlShowBill = Routing.generate('arteo_admin_bill_bill_show', {id: item.bill.id});

                return (
                    <span key={j}>
                        {j !== 0 ?  ','  : ''} <a href={urlShowBill}>{item.bill.code}</a>
                   </span>
                )
            });

            let listingPaymentItemBill = payment.paymentItems.map((item, k) => {

                if (item.bill.product.bookPoliceNumber !== undefined) {

                    return (
                        <span key={k}>
                            {k !== 0 ?  ','  : ''} {item.bill.product.bookPoliceNumber}
                        </span>
                    )
                }
            });

            const urlShowPayment = Routing.generate('arteo_admin_payment_show', {id:payment.id});

            return (

                <tr key={key}>
                    <td>
                        <div className="be-checkbox be-checkbox-color inline">
                            <input key={payment.id} type="checkbox" name="check_payment[]" id={`check-payment-${payment.id}`} value={payment.id} className="check-payment" onChange={this.checkItem.bind(this, payment.id)}/>
                            <label htmlFor={`check-payment-${payment.id}`}> </label>
                        </div>
                    </td>
                    <td className="text-center">
                        <a href={urlShowPayment}>{payment.datePayment}</a>
                    </td>
                    <td>
                        {listingPaymentItem}
                    </td>
                    <td>
                        {payment.customer.name}
                    </td>
                    <td>
                        {listingPaymentItemBill}
                    </td>
                    <td>
                        {payment.paymentChoice}
                    </td>
                    <td className="text-right">
                        {numberFormatter.format(payment.amount)}
                    </td>
                    <td>
                        {payment.banked
                            ? (<span className="badge badge-success"> {Translator.trans('arteo.admin_payment.search.banked')}</span>)
                            : (<span className="badge badge-danger"> {Translator.trans('arteo.admin_payment.search.noBanked')}</span>)
                        }
                    </td>
                </tr>
            )
        });

        return (
            <form name="form-table" id="form-table-payment" action={urlIndex} method="post">
                <div className="row datatable-selection md-mb-15">
                    <div className="col-md-12">
                        {this.state.isGranted  ? dropdownAction() : ''}
                    </div>
                </div>

                <div className="card md-mb-15 card-border-color card-border-color-primary">
                    <div className="card-body">
                        <div className="row datatable-body">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover datatable">
                                        <thead>
                                        <tr>
                                            <th>
                                                <div className="be-checkbox be-checkbox-color inline">
                                                    <input key={this.state.currentPage} type="checkbox" id={`check-all-payment-${this.state.currentPage}`} className="check-all" data-check="check-payment" onChange={this.checkAll.bind(this, this.state.currentPage)}/>
                                                    <label htmlFor={`check-all-payment-${this.state.currentPage}`}> </label>
                                                </div>
                                            </th>
                                            <th className="text-center">

                                                {this.state.orderBy !== "datePayment"
                                                    ? (<a href="#" onClick={this.orderDateAsc.bind(this)}><i className="fa fa-sort" > </i>{Translator.trans('arteo.admin_payment.search.datePayment')}</a>)
                                                    : (this.state.directionOrderBy === "desc")
                                                        ? (<a href="#" onClick={this.orderDateAsc.bind(this)}> <i className="fa fa-sort-up" > </i>{Translator.trans('arteo.admin_payment.search.datePayment')} </a>)
                                                        : (<a href="#" onClick={this.orderDateDesc.bind(this)}> <i className="fa fa-sort-down" > </i>{Translator.trans('arteo.admin_payment.search.datePayment')}</a>)}

                                            </th>
                                            <th>
                                                {Translator.trans('arteo.admin_payment.search.bills')}
                                            </th>
                                            <th>
                                                {Translator.trans('arteo.admin_payment.search.customer')}
                                            </th>
                                            <th>
                                                {Translator.trans('arteo.admin_payment.search.lp')}
                                            </th>
                                            <th>
                                                {Translator.trans('arteo.admin_payment.search.paymentChoice')}
                                            </th>
                                            <th>
                                                {Translator.trans('arteo.admin_payment.search.amount')}
                                            </th>
                                            <th>
                                                {Translator.trans('arteo.admin_payment.search.state')}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tfoot>
                                        <tr>
                                            <th colSpan="6" className="text-right">
                                                {Translator.trans('arteo.admin_payment.search.total')}
                                            </th>
                                            <th className="text-right">
                                                {numberFormatter.format(totalAmount)}
                                            </th>
                                        </tr>
                                        </tfoot>
                                        <tbody>
                                        {
                                            this.state.loadingSpinner === true ?
                                                (
                                                    <tr>
                                                        <td colSpan={8} className="text-center">
                                                            <Spinner className="primary" />
                                                        </td>
                                                    </tr>
                                                )
                                                :
                                                (
                                                    listingPayments
                                                )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row align-items-center">
                            <div className="col-md-4">
                                <ReactPaginate
                                    breakClassName="page-item"
                                    breakLabel={span}
                                    pageClassName="page-item"
                                    previousClassName="page-item"
                                    nextClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    pageCount={this.state.nbTotalPaymentsPage}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handleClickPage.bind(this)}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                    nextLabel={Translator.trans("app.pagination.next")}
                                    previousLabel={Translator.trans("app.pagination.previous")}
                                />
                            </div>
                            <div className="col-md-8">
                                <select className="float-right selectpicker" onChange={this.handleSelectNbItem.bind(this)}>
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="150">150</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}