import React from "react";
import ParserHTML from "../parser/ParserHTML";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from "bazinga-translator";
import {Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import ReactPaginate from "react-paginate";

let parserHTML = new ParserHTML();

export default class EventList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      loadingEvents: false,
      customerId: this.props.customerId,
      selectedEvent: false,
      formEvent: false,
      showModalEvent: false,
      loadingFormEvent: false,
      eventPerPage:5,
      eventOffsetPage: 0,
      nbTotalEventPage: 5,
    };
  }

  async componentDidMount () {
    this.loadEvents();
  }

  initInput = () => {
    App.initSelectPicker();
    App.initDatePicker();
  }

  loadEvents = async () => {
    this.setState({
      loadingEvents: true
    });

    let url = Routing.generate('calendar_events_serialize_user', {
      limit: this.state.eventPerPage,
      offset: this.state.eventOffsetPage
    });

    if(this.state.customerId !== undefined) {
      url += '?customerId=' + this.state.customerId;
    }

    try {
      let response = await fetch(url, {
        method: "GET",
        credentials: "same-origin"
      });

      if (response.status == 200) {
        let datas = await response.json();
        this.setState({
          events: JSON.parse(datas.events),
          nbTotalEventPage: Math.ceil(datas.nbEvents / this.state.eventPerPage),
          loadingEvents: false
        });
      }
    } catch(error) {
      console.log(error);
    }
  }

  removeEvent = async (eventId) => {
    this.setState({
      loadingEvents: true
    });

    let url = Routing.generate('calendar_events_remove', {id: eventId});

    try {
      let response = await fetch(url, {
        method: "GET",
        credentials: "same-origin"
      });

      if (response.status == 201) {
        this.setState({
          loadingEvents: false,
        }, () => {
          this.loadEvents();
        });
      }
    } catch(error) {
      console.log(error);
    }
  }

  editEvent = async event  => {
    this.setState({
      loadingFormNote: true,
      loadingNoteModal: true
    });

    let url = Routing.generate('calendar_events_edit', {id : event.id});

    try {
      let response = await fetch(url, {
        method: "GET",
        credentials: "same-origin"
      });

      if (response.status == 200) {
        this.setState({
          selectedEvent : event
        });
        let form = await response.text();
        this.initEventForm(form);
      }
    } catch(error) {
      console.log(error)
    }
  }

  initEventForm = (form) => {
    this.setState(
        {
          formEvent: parserHTML.parse(form),
          showModalEvent: true,
          loadingFormEvent: false
        }, () => {
          this.initInput();
        }
    );
  }


  submitEventForm = async event => {
    this.setState({
      loadingFormNote: true
    });

    let form = document.getElementById("event_form");

    try {
      let url = Routing.generate('calendar_events_edit', {id: event.id});

      let response = await fetch(url, {
        method: "POST",
        body: new FormData(form),
        credentials: "same-origin"
      });

      if (response.status == 201) {

        let responseData = await response.json();
        this.closeModalEvent();
      } else {
        let form = await response.text();
        this.initEventForm(form);
      }
    } catch (error) {
      console.log(error);
    }
  }

  closeModalEvent = () => {
    this.setState({
      showModalEvent: false
    });
  }

  async handleClickPages(page) {
    let offset = Math.ceil(page.selected * this.state.eventPerPage);

    this.setState({
      eventOffsetPage: offset
    }, () => {
      this.loadEvents();
    })
  }

  render() {
    let formModalEvent = (
        <Modal
            size="lg"
            isOpen={this.state.showModalEvent}
            toggle={this.closeModalEvent.bind(this)}
            onOpened={this.initInput.bind(this)}
        >
          <ModalHeader toggle={this.closeModalEvent.bind(this)}>
            Evènement {this.state.selectedEvent !== null ? '#' + this.state.selectedEvent.id : ''}
          </ModalHeader>
          <ModalBody>
            {
              this.state.loadingFormEvent === true ?
                  (
                    <div className="text-center"><Spinner className="primary" /></div>
                  )
                  :
                  (
                      <div>
                        {this.state.formEvent}
                      </div>
                  )
            }
          </ModalBody>
          <ModalFooter>
            <button
                className="btn btn-primary"
                onClick={this.submitEventForm.bind(this, this.state.selectedEvent)}
            >
              {Translator.trans("app.form.submit")}
            </button>
            <button
                className="btn btn-secondary"
                onClick={this.closeModalEvent.bind(this)}
            >
              {Translator.trans("app.form.cancel")}
            </button>
          </ModalFooter>
        </Modal>
    );
    let listEvents = this.state.events.map((item, key) => {
      let dateStart = new Date(item.event_start);
      let dateEnd = new Date(item.event_end);
      let options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};
      return (
        <tr key={key}>
          <td>
            {item.customer !== undefined ? item.customer.name : ""}
          </td>
          <td>
            {item.title ? <span>{item.title.substring(0,15)}<br /></span> : ""}
          </td>
          <td>
            {item.user.last_name} {item.user.first_name}
          </td>
          <td>
            {dateStart.toLocaleDateString("fr-FR", options)}
          </td>

          <td>
            <div className="row">
            <button onClick={this.removeEvent.bind(this, item.id)} className="btn btn-primary">
              <i className="fas fa-check-circle"></i>
            </button>
            <button onClick={this.editEvent.bind(this, item)} className="btn btn-primary ml-2">
              <i className="fas fa-edit"></i>
            </button>
            </div>
          </td>
        </tr>
      );
    });

    return(
      <div className="card card-border-color card-border-color-primary container-events">
        <div className="card-body">
          <div className="card-title">
            <h5>{Translator.trans("calendar.component.list.title")}</h5>
          </div>
          <table className="table table-hover table-striped">
            <thead>
            <tr>
              <th>{Translator.trans("calendar.component.list.table.header.customer")}</th>
              <th>{Translator.trans("calendar.component.list.table.header.title")}</th>
              <th>{Translator.trans("calendar.component.list.table.header.author")}</th>
              <th>{Translator.trans("calendar.component.list.table.header.date")}</th>
              <th>{Translator.trans("calendar.component.list.table.header.actions")}</th>
            </tr>
            </thead>
            <tbody>
            {
              this.state.loadingEvents === true ?
                (
                  <tr className="text-center">
                    <td colSpan={5}>
                      <Spinner className="primary" />
                    </td>
                  </tr>
                )
                :
                listEvents
            }
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-md-12 ml-2">
            <ReactPaginate
                breakClassName="page-item"
                breakLabel={
                  <span className="page-link">
                    ...
                  </span>
                }
                pageClassName="page-item"
                previousClassName="page-item"
                nextClassName="page-item"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                pageCount={this.state.nbTotalEventPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handleClickPages.bind(this)}
                containerClassName={"pagination"}
                activeClassName={"active"}
                nextLabel={Translator.trans("app.pagination.next")}
                previousLabel={Translator.trans("app.pagination.previous")}
            />
          </div>
        </div>
        {formModalEvent}
      </div>
    );
  }
}