import React from "react";
import { List, arrayMove } from "react-movable";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from "bazinga-translator";
import ParserHTML from "../parser/ParserHTML";

const htmlParser = new ParserHTML();

export default class ExpositionViewList extends React.Component {
    state = {
        loading: false,
        idExpo: this.props.idExpo,
        arrViews: [],
        notification: null,
    }

    loadViews = async () => {
        try {
            this.setState({ loading: true });

            const response = await fetch(Routing.generate("arteo_admin_art_exposition_api_views", { id: this.state.idExpo }), {
                method: "GET",
                credentials: "same-origin"
            });

            if (response.status === 200) {
                const arrViews = (await response.json()).map(view => {
                    return { ...view, isChecked: false };
                });

                this.setState({ arrViews: arrViews, loading: false });
            }

        } catch (error) {
            console.log(error);

            this.setState({ loading: false });
        }
    }

    updateCheckboxState = (index, view, isChecked = null) => {
        let arrViews = [...this.state.arrViews];

        if (isChecked !== null) {
            view.isChecked = isChecked;
        } else {
            view.isChecked = !view.isChecked;
        }

        arrViews.splice(index, 1, view);

        this.setState({ ...this.state, arrViews: arrViews });

        const arrCheckedIdViews = this.state.arrViews.filter(view => {
            return view.isChecked;
        }).map(view => view.id);

        this.inputToSubmit.value = arrCheckedIdViews.join(',');
    }

    loadDomElements = () => {
        this.inputToSubmit = document.querySelector('#checked-views');
    }

    toggleCheckAll = () => {
        let isChecked = document.querySelector('#check-all-views').checked;

        this.state.arrViews.forEach((media, index) => {
            this.updateCheckboxState(index, media, isChecked);
        });
    }

    onDragView = async (oldIndex, newIndex) => {
        let arrViews = this.state.arrViews;

        arrViews = arrayMove(arrViews, oldIndex, newIndex);

        this.setState({ arrViews: arrViews });

        let hasError = false;
        const form = new FormData();

        form.append("views", JSON.stringify(arrViews));

        const url = Routing.generate("arteo_admin_art_exposition_order_view", { id: this.state.idExpo });

        try {
            const response = await fetch(url, {
                method: "POST",
                body: form,
                credentials: "same-origin"
            });

            if (response.status !== 200) {
                hasError = true;
            }

        } catch (error) {
            hasError = true;
        }

        if (hasError) {
            arrViews = arrayMove(arrViews, newIndex, oldIndex);

            this.setState({
                arrViews: arrViews,
                notification: { type: "danger", message: "Une erreur est survenue" }
            });
        }

    };

    componentDidMount = async () => {
        this.loadViews();
        this.loadDomElements();
    }

    render() {
        let viewListDraggable = (
            <List
                values={this.state.arrViews}
                onChange={({ oldIndex, newIndex }) => this.onDragView(oldIndex, newIndex)}
                renderList={({ children, props, isDragged }) => (
                    <div className="table-responsive">
                        <table className="table table-striped table-hover datatable">
                            <thead>
                                <tr>
                                    <th>
                                        <input type="checkbox" id="check-all-views" onChange={() => {
                                            this.toggleCheckAll();
                                        }} />
                                    </th>
                                    <th>
                                        {Translator.trans('arteo.admin_art.exposition.search.table.view.imageName')}
                                    </th>
                                    <th>
                                        {Translator.trans('arteo.admin_art.exposition.search.table.view.title')}
                                    </th>
                                    <th>
                                        {Translator.trans('arteo.admin_art.exposition.search.table.view.description')}
                                    </th>
                                    <th>
                                        {Translator.trans('arteo.admin_art.exposition.search.table.view.dateAdded')}
                                    </th>
                                    <th className="text-right">
                                        {Translator.trans('arteo.actions')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody {...props}>{children}</tbody>
                        </table>
                    </div>
                )}
                renderItem={({ index, value, props, isDragged, isSelected }) => {
                    const urlShowView = Routing.generate('arteo_admin_art_exposition_show_view', { id: value.id });
                    const urlEditView = Routing.generate('arteo_admin_art_exposition_edit_view', { id: value.id });

                    const row = (
                        <tr
                            {...props}
                            style={{
                                cursor: isDragged ? "grabbing" : "grab",
                                backgroundColor: isDragged || isSelected ? "#EEE" : "#fafafa",
                                position: isDragged || isSelected ? "absolute" : "inherit",
                                width: isDragged || isSelected ? "100%" : "inherit",
                                display: isDragged || isSelected ? "flex" : "table-row"
                            }}
                        >
                            <td>
                                <input type="checkbox" id={'check-view-' + value.id} value={value.id} checked={value.isChecked} onChange={() => {
                                    this.updateCheckboxState(index, value)
                                }} />
                            </td>
                            <td className="user-avatar cell-detail user-info">
                                <a href={urlShowView}>
                                    <img src={value.img} alt={value.title} />
                                </a>
                            </td>
                            <td>
                                <a href={urlShowView}>
                                    {value.title}
                                </a>
                            </td>
                            <td>
                                {htmlParser.parse(value.description)}
                            </td>
                            <td>
                                {value.created}
                            </td>
                            <td className="text-right">
                                <a href={urlShowView} className="mr-3">
                                    <i className="fa fa-eye"></i>
                                </a>
                                {value.canEdit ?
                                    <a href={urlEditView} className="mr-3">
                                        <span className="fas fa-edit"></span>
                                    </a>
                                    : null
                                }
                            </td>
                        </tr>
                    );

                    return isDragged ? (
                        <div className="table-responsive">
                            <table className="table table-hover table-striped" style={{ ...props.style, borderSpacing: 0 }}>
                                <tbody>{row}</tbody>
                            </table>
                        </div>
                    ) : (
                            row
                        );
                }}
            />
        );

        return (
            <div>
                {viewListDraggable}
            </div>
        )
    }
}
