import React from "react";
import ParserHTML from "../parser/ParserHTML";
import ReactPaginate from "react-paginate";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from "bazinga-translator";
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import ModalNote from './ModalNote';

let parserHTML = new ParserHTML();

export default class NoteList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: [],
      formNote: null,
      loadingNotes: false,
      showModalNote: false,
      loadingNoteModal: false,
      customerId: this.props.customerId,
      notePerPage:5,
      noteOffsetPage: 0,
      nbTotalNotePage: 5,
      noteSelectedId: null,
    };

  }

  async componentDidMount () {
    this.loadNotes();
  }

  loadNotes = async () => {
    this.setState({
      loadingNotes: true
    });

    let url = Routing.generate('arteo_bundle_note_all_notes_user', {
      limit: this.state.notePerPage,
      offset: this.state.noteOffsetPage
    });

    if(this.state.customerId !== undefined) {
      url += '?customerId=' + this.state.customerId;
    }

    try {
      let response = await fetch(url, {
        method: "GET",
        credentials: "same-origin"
      });

      if (response.status == 200) {
        let datas = await response.json();

        this.setState({
          notes: JSON.parse(datas.notes),
          nbTotalNotePage: Math.ceil(datas.nbNotes / this.state.notePerPage),
          loadingNotes: false
        });
      }
    } catch(error) {
      console.log(error);
    }
  }

  initInput = () => {
    App.initSelectPicker();
    App.initDatePicker();
  }

  /**
   * Method to init form ModalNoteForm
   *
   * @param form
   */
  initNoteForm = (form) => {
    this.setState(
        {
          formNote: parserHTML.parse(form),
          showModalNote: true,
          loadingFormNote: false
        }, () => {
          this.initInput();
        }
    );
  }

  clickLoadModal = async note => {
    this.setState({
      loadingFormNote: true,
      loadingNoteModal: true
    });

    let url = Routing.generate('arteo_bundle_note_edit', {id : note.id});

    try {
      let response = await fetch(url, {
        method: "GET",
        credentials: "same-origin"
      });

      if (response.status == 200) {
        let form = await response.text();

        this.setState({
          noteSelectedId: note.id,
        }, () => {
          this.initNoteForm(form);
        });
      }

    } catch(error) {
      console.log(error)
    }
  }

  closeModalNote = () => {
    this.setState({
      showModalNote: false
    });
  }

  submitNoteForm = async () => {
    this.setState({
      loadingFormNote: true
    });

    let form = document.getElementById("note-form-modal");

    try {
      let url = Routing.generate('arteo_bundle_note_edit', {id: this.state.noteSelectedId});

      let response = await fetch(url, {
        method: "POST",
        body: new FormData(form),
        credentials: "same-origin"
      });

      if (response.status == 201) {

        let responseData = await response.json();
        this.loadNotes();
        this.closeModalNote();
      } else {
        let form = await response.text();
        this.initNoteForm(form);
      }
    } catch (error) {
      console.log(error);
    }
  }

  deleteNote = async note => {
    let url = Routing.generate('arteo_bundle_note_delete', {id : note.id});

    if(this.state.customerId !== undefined) {
      url += '?customerId=' + this.state.customerId;
    }

    try {
      let response = await fetch(url, {
        method: "GET",
        credentials: "same-origin"
      });
      if (response.status == 200) {
        let datas = await response.json();
        this.loadNotes();
      }
    }
    catch (e) {
      console.log(error);
    }
  }

  async handleClickPages(page) {
    let offset = Math.ceil(page.selected * this.state.notePerPage);

    this.setState({
      noteOffsetPage: offset
    }, () => {
      this.loadNotes();
    })
  }


  render() {
    let formModalNote = (
        <Modal
            size="lg"
            isOpen={this.state.showModalNote}
            toggle={this.closeModalNote.bind(this)}
            onOpened={this.initInput.bind(this)}
        >
          <ModalHeader toggle={this.closeModalNote.bind(this)}>
            Note {this.state.noteSelectedId !== null ? '#' + this.state.noteSelectedId : ''}
          </ModalHeader>
          <ModalBody>
            {
              this.state.loadingFormNote === true ?
                  (
                    <div className="text-center"><Spinner className="primary" /></div>
                  )
                  :
                  (
                      <div>
                        {this.state.formNote}
                      </div>
                  )
            }
          </ModalBody>
          <ModalFooter>
            <button
                className="btn btn-primary"
                onClick={this.submitNoteForm.bind(this)}
            >
              {Translator.trans("app.form.submit")}
            </button>
            <button
                className="btn btn-secondary"
                onClick={this.closeModalNote.bind(this)}
            >
              {Translator.trans("app.form.cancel")}
            </button>
          </ModalFooter>
        </Modal>
    );

    let listNotes = this.state.notes.map((item, key) => {
      let dateNote = new Date(item.date_note);
      let options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};

      return (
        <tr key={key}>
          <td>{item.id}</td>
          <td>{item.customer !== undefined ? item.customer.name : ''}</td>
          <td>{dateNote.toLocaleDateString("fr-FR", options)}</td>
          <td>{item.description !== undefined ? (item.description.length > 10 ? <a data-toggle = "tooltip" title = {item.description} >{item.description.substring(0, 10) + '...'}</a> : item.description) : ''}</td>
          <td>{item.author !== undefined ? item.author.last_name : ''} {item.author !== undefined ? item.author.first_name : ''}</td>
          <td>
            <div className="row">
              <button onClick={this.clickLoadModal.bind(this, item)} className="btn btn-primary"><i className="fas fa-eye"></i></button>
              <button onClick={this.deleteNote.bind(this, item)} className="btn btn-primary ml-2"><i className="fas fa-trash-alt"></i></button>
            </div>
          </td>
        </tr>
      );
    });

    return(
      <div className="card card-border-color card-border-color-primary container-note">
        <div className="card-body">
          <div className="row">
            <div className="col-md-8">
              <h5 className="card-title delete-margin"> {Translator.trans("arteo.note.card.headerList")}</h5>
            </div>
            <div className="col-md-4 text-right">
              <ModalNote customerId={this.state.customerId} />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover table-striped">
              <thead>
              <tr>
                <th>{Translator.trans("arteo.note.table.header.id")}</th>
                <th>{Translator.trans("arteo.note.table.header.customer")}</th>
                <th>{Translator.trans("arteo.note.table.header.dateNote")}</th>
                <th>{Translator.trans("arteo.note.table.header.description")}</th>
                <th>{Translator.trans("arteo.note.table.header.author")}</th>
                <th>{Translator.trans("arteo.note.table.header.actions")}</th>
              </tr>
              </thead>
              <tbody>
              {
                this.state.loadingNotes === true ?
                  (
                    <tr className="text-center">
                      <td colSpan={6}>
                        <Spinner className="primary" />
                      </td>
                    </tr>
                  )
                  :
                  listNotes
              }
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 ml-2">
            <ReactPaginate
                breakClassName="page-item"
                breakLabel={
                  <span className="page-link">
                    ...
                  </span>
                }
                pageClassName="page-item"
                previousClassName="page-item"
                nextClassName="page-item"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                pageCount={this.state.nbTotalNotePage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handleClickPages.bind(this)}
                containerClassName={"pagination"}
                activeClassName={"active"}
                nextLabel={Translator.trans("app.pagination.next")}
                previousLabel={Translator.trans("app.pagination.previous")}
            />
          </div>
        </div>
        {formModalNote}
      </div>
    );
  }
}