import React, { Component } from "react";

import Routing from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
const routes = require("../../../../../../../../js/fos_js_routes.json");

/**
 * Basic component for autocomplete field
 */
export default class AutocompleteField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value === undefined ? "" : this.props.value,
        };
    }

    componentDidMount() {
        App.initAutocomplete();
    }

    onChange = e => {
        let targetValue = e.target.value;

        this.setState(
          {
              value: targetValue
          }
        );
    };

    render() {
        return (
            <div className="row">
                <div className="col-md-8">
                    <input
                        type={this.props.type}
                        id={this.props.id}
                        name={this.props.name}
                        required={this.props["data-required"] == "true" ? "required" : ""}
                        autoComplete={this.props.autoComplete}
                        className={this.props.class}
                        data-delay={this.props["data-delay"]}
                        data-max-items={this.props["data-max-items"]}
                        data-min-chars={this.props["data-min-chars"]}
                        data-multiple={this.props["data-multiple"]}
                        data-origin-value={this.props["data-origin-value"]}
                        data-property={this.props["data-property"]}
                        data-render={this.props["data-render"]}
                        data-required={this.props["data-required"]}
                        data-route={this.props["data-route"]}
                        data-route-params={this.props["data-route-params"]}
                        data-target={this.props["data-target"]}
                        data-url={this.props["data-url"]}
                        data-on-select-callback={this.props["data-on-select-callback"]}
                        data-redirectroute={this.props["data-redirectroute"]}
                        value={this.state.value}
                        placeholder={this.props.placeholder}
                        readOnly={false}
                        disabled={false}
                        style={this.props.style}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className="col-md-4">{this.props["researcher-product-children"] && <ResearcherProductChildren />}</div>
            </div>
        );
    }
}
