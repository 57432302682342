import React from "react";
import {css} from '@emotion/core';
import {FadeLoader} from "react-spinners";
import ParserHTML from "../parser/ParserHTML";
import Select from 'react-select';


import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
const routes = require("../../../../../../../js/fos_js_routes.json");
import Translator from "bazinga-translator";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ReactPaginate from "react-paginate";

const translationsConfig = require("../../../../../../../js/translations/fr.json");
Translator.fromJSON(translationsConfig);

const numberFormatter = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" });

const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
`;

let parserHTML = new ParserHTML();

export default class ProspectionForCustomer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      flashMessages: [],
      artworks: [],
      artworksChecked: [],
      loadingArtworks: false,
      customerId: this.props.customerId,
      artworkPerPage: 10,
      artworkOffsetPage: 0,
      nbTotalArtworkPage: 10,
      keywordsFilter: [],
      keywordsOptions: [],
      artistsFilter: [],
      artistsOptions: [],
      categoriesFilter: [],
      categoriesOptions: [],
      customer: null,
      modalForm: null,
      modalIsOpen: false,
      productStatesFilter: [],
      productStatesOptions: [],
      artistAlreadySaledFilter: false,
    };
  }

  async componentDidMount () {
    this.loadCustomer();
  }

  initInput = () => {
    App.initSelectPicker();
    App.initDatePicker();
    App.initCkeditor();
  }

  async loadCustomer() {
    let url = Routing.generate('arteo_admin_customer_prospection_load', {id: this.props.customerId});

    try {
      let response = await fetch(url, {
        method: "GET",
        credentials: "same-origin"
      });

      if (response.status == 200) {
        let datas = await response.json();
        let customer = JSON.parse(datas.customer);

        this.setState({
          product: customer,
          keywordsFilter: [...customer.keywords.map((item, key) => { return {value: item.id, label: item.name}; })],
          keywordsOptions: [...customer.keywords.map((item, key) => { return {value: item.id, label: item.name}; })],
          artistsFilter: [...customer.artists.map((item, key) => { return {value: item.id, label: item.name}; })],
          artistsOptions: [...customer.artists.map((item, key) => { return {value: item.id, label: item.name}; })],
          categoriesFilter: [...customer.categories.map((item, key) => { return {value: item.id, label: item.name}; })],
          categoriesOptions: [...customer.categories.map((item, key) => { return {value: item.id, label: item.name}; })],
          productStatesOptions: [...customer.productStates.map((item, key) => { return {value: item.id, label: item.name}; })],
          loadingArtworks: false
        }, () =>  this.loadArtworks());
      }
    } catch(error) {
      console.log(error);
    }
  }

  async loadArtworks() {
    this.setState({
      loadingArtworks: true
    });

    let url = Routing.generate('arteo_admin_customer_prospection_list', {
      limit: this.state.artworkPerPage,
      offset: this.state.artworkOffsetPage
    });

    url += '?customerId=' + this.state.customerId;

    if(this.state.artistsFilter.length > 0) {
      url += '&artists=' + [...this.state.artistsFilter.map((item,key) => { return item.value; })].join(',');
    }

    if(this.state.categoriesFilter.length > 0) {
      url += '&categories=' + [...this.state.categoriesFilter.map((item,key) => { return item.value; })].join(',');
    }

    if(this.state.keywordsFilter.length > 0) {
      url += '&keywords=' + [...this.state.keywordsFilter.map((item,key) => { return item.value; })].join(',');
    }

    if(this.state.productStatesFilter.length > 0) {
      url += '&productStates=' + [...this.state.productStatesFilter.map((item,key) => { return item.value; })].join(',');
    }

    if(this.state.artistAlreadySaledFilter) {
      url += '&filterArtistAlreadySaled=1';
    }

    try {
      let response = await fetch(url, {
        method: "GET",
        credentials: "same-origin"
      });

      if (response.status == 200) {
        let datas = await response.json();

        this.setState({
          artworks: JSON.parse(datas.artworks),
          nbTotalArtworkPage: Math.ceil(datas.nbArtworks / this.state.artworkPerPage),
          loadingArtworks: false
        });
      }
    } catch(error) {
      console.log(error);
    }
  }

  async handleClickPages(page) {
    let offset = Math.ceil(page.selected * this.state.artworkPerPage);

    this.setState({
      artworkOffsetPage: offset
    }, () => {
      this.loadArtworks();
    });
  }

  notifyGlobal = async () => {
    this.getModalForm();
  }

  handleModal = (open, form = null) => {
    if (form != null) {
      form = parserHTML.parse(form);
    }

    this.setState({
      modalForm: form,
      modalIsOpen: open,
    });
  };

  getModalForm = async () => {

    try {
      const response = await fetch(Routing.generate("arteo_admin_customer_prospection_send", { id: this.props.customerId }) + "?artworks=" + this.state.artworksChecked.join(','), {
        method: "GET",
        credentials: "same-origin",
      });

      if (response.status == 200) {
        const form = await response.text();

        this.handleModal(true, form);
      }
    } catch (error) {
      console.log(error);
    }
  };

  submitModalForm = async () => {
    try {
      $("#product_viewer_customer_content").val($(".ck-content").html());

      const response = await fetch(Routing.generate("arteo_admin_customer_prospection_send", { id: this.props.customerId }) + "?artworks=" + this.state.artworksChecked.join(','), {
        body: new FormData(document.getElementById("product-viewer-customer-form")),
        credentials: "same-origin",
        method: "POST",
      });

      if (response.status == 201) {
        this.handleModal(false);

        const flashMessages = this.state.flashMessages;

        flashMessages.push({ type: "success", message: Translator.trans("arteo.admin_art.product_viewer_customer.flash.send.success") });

        this.setState({ flashMessages: flashMessages });
      } else {
        const form = await response.text();

        this.handleModal(true, form);
      }
    } catch (error) {
      console.log(error);
    }
  };

  addCheckArtwork(artworkId) {
    let artworksChecked = this.state.artworksChecked;

    artworksChecked.push(artworkId);

    this.setState({
      artworksChecked: artworksChecked
    });
  }

  selectKeywords = selectedOption => {
    this.setState({
      keywordsFilter: selectedOption == null ? [] : selectedOption
    }, this.loadArtworks);
  }

  selectArtists = selectedOption => {
    this.setState({
      artistsFilter: selectedOption == null ? [] : selectedOption
    }, this.loadArtworks);
  }

  selectCategories = selectedOption => {
    this.setState({
      categoriesFilter: selectedOption == null ? [] : selectedOption
    }, this.loadArtworks);
  }

  selectProductState = selectedOption => {
    this.setState({
      productStatesFilter: selectedOption == null ? [] : selectedOption
    }, this.loadArtworks);
  }

  filterArtistAlreadySaled() {
    let filter = this.state.artistAlreadySaledFilter;

    this.setState({
      artistAlreadySaledFilter: !filter
    }, this.loadArtworks);
  }

  removeFlashMessage = (flashMessage) => {
    const flashMessages = [];

    this.state.flashMessages.forEach((fm) => {
      if (fm !== flashMessage) {
        flashMessages.push(fm);
      }
    });

    this.setState({
      flashMessages: flashMessages,
    });
  };

  renderFlashMessages() {
    const flashMessages = this.state.flashMessages;

    return flashMessages.map((flashMessage, index) => {
      return (
        <div className={"alert alert-" + flashMessage.type} key={index} role="alert">
          {flashMessage.message}

          <button aria-label="Close" className="close" onClick={this.removeFlashMessage.bind(this, flashMessage)} type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    });
  }

  render() {
    let listArtworks = this.state.artworks.map((item, key) => {

      let styleState = {backgroundColor: item.artwork.state.color};

      return (
        <tr key={key}>
          <td>
            <input type="checkbox" onClick={this.addCheckArtwork.bind(this, item.artwork.id)} defaultChecked={this.state.artworksChecked.includes(item.artwork.id)} />
          </td>
          <td>
            <img src={item.artworkImage} alt={item.artwork.title} />
            <br />
            <a href={item.artworkLink}>{item.artwork.title}</a>
          </td>
          <td>{item.artwork.artist}</td>
          <td>{numberFormatter.format(isNaN(item.artwork.wantedPrice) ? 0 : item.artwork.wantedPrice)}</td>
          <td>
            {item.artwork.category}
          </td>
          <td className="text-center">
            <span className="label" style={styleState}>{item.artwork.state.name}</span>
            <br />
            <span className="label label-black">{item.artwork.acquisitionMode}</span>
          </td>
          <td>
            {item.isNotify ? (<span className="badge badge-success" data-toggle="tooltip" data-placement="bottom" data-original-title={item.notifyDate} >
              <i className="fas fa-check-circle"></i> Envoyé <br />{item.notifyDate}</span>) : ''}
          </td>
        </tr>
      );
    });

    let modal = (
      <Modal isOpen={this.state.modalIsOpen} size="xl" onOpened={this.initInput.bind(this)} toggle={this.handleModal.bind(this, false)}>
        <ModalHeader toggle={this.handleModal.bind(this, false)}>
          {Translator.trans("arteo.admin_art.prospection.product.send")}
        </ModalHeader>
        <ModalBody>{this.state.modalForm}</ModalBody>
        <ModalFooter>
          <button className="btn btn-default" onClick={this.submitModalForm.bind(this)}>
            {Translator.trans("app.form.submit")}
          </button>
          <button className="btn btn-secondary" onClick={this.handleModal.bind(this, false)}>
            {Translator.trans("app.form.cancel")}
          </button>
        </ModalFooter>
      </Modal>
    );

    let flashMessages = this.renderFlashMessages();

    return(
      <>
      <div>
        {flashMessages}
        <div className="card card-border-color card-border-color-primary">
          <div className="card-body">
            <h5 className="card-title">
              {Translator.trans("arteo.admin_art.product.show.prospection.filter")}
            </h5>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="select-keywords">Mots clés :</label>
                <Select isMulti value={this.state.keywordsFilter} onChange={this.selectKeywords} options={this.state.keywordsOptions}/>
              </div>
              <div className="col-md-4">
                <label htmlFor="artist">Artistes :</label>
                <Select isMulti value={this.state.artistsFilter} onChange={this.selectArtists} options={this.state.artistsOptions}/>
              </div>
              <div className="col-md-4">
                <label htmlFor="category">Familles :</label>
                <Select isMulti value={this.state.categoriesFilter} onChange={this.selectCategories} options={this.state.categoriesOptions}/>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-4">
                <label htmlFor="select-keywords">Artistes déjà achetés </label>
                <input type="checkbox" id="category" onChange={this.filterArtistAlreadySaled.bind(this)} checked={this.state.artistAlreadySaledFilter}/>
              </div>
            </div>
            <div className="dropdown mt-2 mb-2">
              <button className="btn btn-default dropdown-toggle" id="table-action-selection" type="button"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {Translator.trans('arteo.list.actions-list')}
              </button>
              <div className="dropdown-menu" aria-labelledby="table-action-selection">
                <button className="dropdown-item btn btn-link" onClick={this.notifyGlobal.bind(this)}
                        data-check="check-product">
                  <i className="fa fa-paper-plane mr-1"></i> Envoyer
                </button>
              </div>
            </div>
            <table className="table table-hover table-striped">
              <thead>
              <tr>
                <th></th>
                <th>Titre</th>
                <th>Artist</th>
                <th>Prix de vente souhaité</th>
                <th>Famille</th>
                <th>
                  <Select isMulti value={this.state.productStatesFilter} onChange={this.selectProductState} options={this.state.productStatesOptions} placeholder="États" />
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              {
                this.state.loadingArtworks === true ?
                  (
                    <tr>
                      <td colSpan={3}>
                        <FadeLoader
                          css={override}
                          sizeUnit={"px"}
                          size={500}
                          color={'#004788'}
                          loading={this.state.loadingArtworks}
                        />
                      </td>
                    </tr>
                  )
                  :
                  listArtworks
              }
              </tbody>
            </table>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col-md-8">
                <ReactPaginate
                  breakClassName="page-item"
                  breakLabel={
                    <span className="page-link">
                        ...
                      </span>
                  }
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  pageCount={this.state.nbTotalArtworkPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handleClickPages.bind(this)}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
        {modal}
      </div>
        </>
    );
  }
}