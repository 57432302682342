import React from "react";
import { List, arrayMove } from "react-movable";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from "bazinga-translator";

export default class MediaList extends React.Component {
    state = {
        loading: false,
        idExpo: this.props.idExpo,
        arrMedias: [],
        notification: null,
    };

    loadMedias = async () => {
        try {
            this.setState({ loading: true });

            const response = await fetch(Routing.generate("arteo_admin_art_exposition_api_medias", { id: this.state.idExpo }), {
                method: "GET",
                credentials: "same-origin",
            });

            if (response.status === 200) {
                const arrMedias = (await response.json()).map((media) => {
                    return { ...media, isChecked: false };
                });

                this.setState({ arrMedias: arrMedias, loading: false });
            }
        } catch (error) {
            console.log(error);

            this.setState({ loading: false });
        }
    };

    updateCheckboxState = (index, media, isChecked = null) => {
        let arrMedias = [...this.state.arrMedias];

        if (isChecked !== null) {
            media.isChecked = isChecked;
        } else {
            media.isChecked = !media.isChecked;
        }

        arrMedias.splice(index, 1, media);

        this.setState({ ...this.state, arrMedias: arrMedias });

        const arrCheckedIdMedias = this.state.arrMedias
            .filter((media) => {
                return media.isChecked;
            })
            .map((media) => media.id);

        this.inputToSubmit.value = arrCheckedIdMedias.join(",");
    };

    loadDomElements = () => {
        this.inputToSubmit = document.querySelector("#checked-medias");
    };

    toggleCheckAll = () => {
        let isChecked = document.querySelector("#check-all-medias").checked;

        this.state.arrMedias.forEach((media, index) => {
            this.updateCheckboxState(index, media, isChecked);
        });
    };

    onDragMedia = async (oldIndex, newIndex) => {
        let arrMedias = this.state.arrMedias;

        arrMedias = arrayMove(arrMedias, oldIndex, newIndex);

        this.setState({ arrMedias: arrMedias });

        let hasError = false;
        const form = new FormData();

        form.append("medias", JSON.stringify(arrMedias));

        const url = Routing.generate("arteo_admin_art_exposition_order_media", { id: this.state.idExpo });

        try {
            const response = await fetch(url, {
                method: "POST",
                body: form,
                credentials: "same-origin",
            });

            if (response.status !== 200) {
                hasError = true;
            }
        } catch (error) {
            hasError = true;
        }

        if (hasError) {
            arrMedias = arrayMove(arrMedias, newIndex, oldIndex);

            this.setState({
                arrMedias: arrMedias,
                notification: { type: "danger", message: "Une erreur est survenue" },
            });
        }
    };

    componentDidMount = async () => {
        this.loadMedias();
        this.loadDomElements();
    };

    render() {
        let mediaListDraggable = (
            <List
                values={this.state.arrMedias}
                onChange={({ oldIndex, newIndex }) => this.onDragMedia(oldIndex, newIndex)}
                renderList={({ children, props, isDragged }) => (
                    <div className="table-responsive">
                        <table className="table table-striped table-hover datatable">
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            id="check-all-medias"
                                            onChange={() => {
                                                this.toggleCheckAll();
                                            }}
                                        />
                                    </th>
                                    <th>{Translator.trans("arteo.admin_art.exposition.search.table.media.title")}</th>
                                    <th>{Translator.trans("arteo.admin_art.exposition.search.table.media.author")}</th>
                                    <th>{Translator.trans("arteo.admin_art.exposition.search.table.media.source")}</th>
                                    <th>{Translator.trans("arteo.admin_art.exposition.search.table.media.dateAdded")}</th>
                                    <th className="text-right">{Translator.trans("arteo.actions")}</th>
                                </tr>
                            </thead>
                            <tbody {...props}>{children}</tbody>
                        </table>
                    </div>
                )}
                renderItem={({ index, value, props, isDragged, isSelected }) => {
                    const urlShowMedia = Routing.generate("arteo_admin_art_exposition_show_media", { id: value.id });
                    const urlEditMedia = Routing.generate("arteo_admin_art_exposition_edit_media", { id: value.id });
                    const created = value.created;
                    const dateCreated = created.date ? new Date(created.date).toLocaleDateString() : "";
                    const row = (
                        <tr
                            {...props}
                            style={{
                                cursor: isDragged ? "grabbing" : "grab",
                                backgroundColor: isDragged || isSelected ? "#EEE" : "#fafafa",
                                position: isDragged || isSelected ? "absolute" : "inherit",
                                width: isDragged || isSelected ? "100%" : "inherit",
                                display: isDragged || isSelected ? "flex" : "table-row",
                            }}
                        >
                            <td>
                                <input
                                    type="checkbox"
                                    id={"check-media-" + value.id}
                                    className="check-media"
                                    value={value.id}
                                    checked={value.isChecked}
                                    onChange={() => {
                                        this.updateCheckboxState(index, value);
                                    }}
                                />
                            </td>
                            <td>
                                <a href={urlShowMedia} target="_blank">
                                    {value.title}
                                </a>
                            </td>
                            <td>{value.author}</td>
                            <td>{value.source}</td>
                            <td>{dateCreated}</td>
                            <td className="text-right">
                                <a href={urlShowMedia} className="mr-3">
                                    <i className="fa fa-eye"></i>
                                </a>
                                {value.canEdit ? (
                                    <a href={urlEditMedia} className="mr-3">
                                        <span className="fas fa-edit"></span>
                                    </a>
                                ) : null}
                            </td>
                        </tr>
                    );

                    return isDragged ? (
                        <div className="table-responsive">
                            <table className="table table-hover table-striped" style={{ ...props.style, borderSpacing: 0 }}>
                                <tbody>{row}</tbody>
                            </table>
                        </div>
                    ) : (
                        row
                    );
                }}
            />
        );

        return <div>{mediaListDraggable}</div>;
    }
}
